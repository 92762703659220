
export function retinacss( className, image_data ) {
    var inlinestyle = ``;
    inlinestyle += `.`+className+` {
        background-image: url('`+process.env.PREACT_APP_API_URL+'/assets/'+image_data.id+'?fit=contain&width=300&height=300&quality=80'+`');
    }
    ` + retina( className, image_data ) + `
    `;
    inlinestyle += `
        @media (min-width: 900px) {
            .`+className+` {
                background-image: url('`+process.env.PREACT_APP_API_URL+'/assets/'+image_data.id+`');
            }
        }`;
    return inlinestyle;
}

function retina( className, image_data ){
    return `@media screen and (-webkit-min-device-pixel-ratio: 2),
        screen and (min--moz-device-pixel-ratio: 2),
        screen and (-moz-min-device-pixel-ratio: 2),
        screen and (-o-min-device-pixel-ratio: 2/1),
        screen and (min-device-pixel-ratio: 2),
        screen and (min-resolution: 192dpi),
        screen and (min-resolution: 2dppx) {
            .`+className+` {
                background-image: url('`+process.env.PREACT_APP_API_URL+'/assets/'+image_data.id+'?fit=contain&width=600&height=600&quality=80'+`');
            }
    }`
}