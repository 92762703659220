import { useState } from 'preact/hooks';
import { makeStyles, Menu } from '@material-ui/core';
import cx from 'classnames';
import AddMenu from '../addmenu';

const useStyles = makeStyles(theme => ({
    addmenu: {
        paddingLeft: 0,
        paddingRight: 0,
        width: 'auto',
        marginTop: 10,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.secondary.main,
        borderWidth: 1,
        borderStyle: 'solid',
        width: '205px'

    }
}));


//export default class HeaderDropDown extends Component {
export default function HeaderDropDown() {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const id = 'add-popover'

    const plusC = cx( 'sprite', {
        'plus': !Boolean(anchorEl),
        'minus': Boolean(anchorEl)
    } );

    const style = useStyles();
    
    return (
        <div>
            <a aria-describedby={id} aria-haspopup="true" title={this.props.title} aria-label={this.props.title} onClick={handleClick}>
                <i class={plusC} aria-hidden="true">Add</i>
            </a>
            <Menu id={id} anchorEl={anchorEl} getContentAnchorEl={null} transformOrigin={{ vertical: 'top', horizontal: 'center'}} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={Boolean(anchorEl)} onClose={handleClose} classes={{paper:style.addmenu}} style={{ paddingRight: 0, width: '100% !important'}}>
                <AddMenu close={this.handleClose}/>
            </Menu>
        </div>
    );

}

                    