import { combineReducers } from 'redux';
import { modal } from './modal.reducer';
import { auth } from './auth.reducer';
import { memo } from './memo.reducer';
import { profile } from './profile.reducer';
import { schools } from './schools.reducer';
import { events } from './events.reducer';
import { mentors, careers } from './staff.reducer';
import { students } from './students.reducer';
import { dash } from './dash.reducer';
import { areas } from './areas.reducer';
import { actions } from './actions.reducer';
import { customLinks } from './customlinks.reducer';
import { notifications } from './notifications.reducer';

const reducer = combineReducers( {
    modal,
    auth,
    memo,
    profile,
    schools,
    events,
    mentors,
    careers,
    students,
    areas,
    actions,
    customLinks,
    dash,
    notifications
} );

export default reducer;
