export const personConst = {
    STUDENT: 'students',
    MENTOR: 'mentors',
    CAREER: 'career_advisors',
    ADMIN: 'Admin'
};
export const roleConst = {
    STUDENT: 4,
    MENTOR: 7,
    CAREER: 5
};
