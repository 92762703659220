export const fetchConst = {
    OPTIONS: {
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        redirect: 'error', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
    },
    NETWORK_ERROR: 'Network error, possible lack of internet',
    CATCH_ERROR: 'An error occured'
};
