import MenuLinks from '../../../_helpers/MenuLinks';
import { Link as RouterLink } from 'preact-router';
import { Grid, Button } from '@material-ui/core';
import style from '../style.scss';

export const SectorsHeader = () => (
    <div class={'dark_blue_header'}>
        <div class={'header_content '+ style.padded}>
            <h1>Industry Sectors</h1>
            <p>Learn more about our Industry sectors and discover the businesses and workplaces you might be interested in.</p>
        </div>
    </div>
);

export const SectorHeader = ( props ) => (
    <div class={props.sector.tag+' fullwidthbg angledup'}>
        <style scoped dangerouslySetInnerHTML={{__html: props.inlinestyle}}></style>
        <div class="header_content dropped pushdown">
            <h1>{props.sector.title}</h1>
            <p>{props.sector.sub_title}</p>
        </div>
    </div>
);

export const SubPreview = ( props ) => (
    <Grid container spacing={5} className={'padout '+style.sub_preview}>
        <SubPreviewImage left={true} alt={props.left} sectorTag={props.tag} item={props.item} />
        <Grid item sm={6} xs={12}>
            <h2><a href={MenuLinks.IndustrySectors+'/'+props.tag+'/'+props.item.tag} >
                {props.item.title}
            </a></h2>
            <p>{props.item.short_description}</p>
            <Button color="secondary" variant="contained" href={MenuLinks.IndustrySectors+'/'+props.tag+'/'+props.item.tag}>
                Find out more
            </Button>
        </Grid>
        <SubPreviewImage left={false} alt={!props.left} sectorTag={props.tag} item={props.item}/>
    </Grid>
);

export const SubPreviewImage = ( { left, alt, sectorTag, item } ) => (
    <Grid item sm={6} xs={12} className={(!alt?style.hide:'') + ' ' + (left?style.onleft : style.onright)}>
        <RouterLink href={MenuLinks.IndustrySectors+'/'+sectorTag+'/'+item.tag} >
            {item.image && <img src={process.env.PREACT_APP_API_URL+'/assets/'+item.image.id+'?fit=cover&width=768&height=900&quality=80'} class={style.subimage} />}
        </RouterLink>
    </Grid>
);

export const Sub = ( props ) => (
    <div class={'maxwidth ' + style.subitem}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <h3>{props.item.title}</h3>
                <p>{props.item.description}</p>
            </Grid>
            <Grid item xs={12} sm={8}>
                {props.item.image && <img src={process.env.PREACT_APP_API_URL+'/assets/'+props.item.image.id} />}
            </Grid>
            <Grid item xs={12} sm={4}>
                {props.item.listtitle && <h4>{props.item.listtitle}</h4>}
                <div dangerouslySetInnerHTML={{__html: props.item.list}} />
            </Grid>
        </Grid>
    </div>
   
);