import { staffConst } from '../_constants/staff.constants';
import { staffService } from '../_services/staff.service';
import { roleConst } from '../_constants/person.constants';
import { memoActions } from '../_actions/memo.actions';

export const staffActions = {
    loadMentors,
    loadCareers
};

function loadMentors() {
    return dispatch => {
        staffService.get( roleConst.MENTOR )
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( json => {
                if ( json && json.data ) {
                    if ( json.data.length > 0 ) dispatch( mentor_success( json.data ) );
                } else {
                    dispatch( failure( json ) );
                }
            } );
    }

    function mentor_success( mentors ) {
        return { type: staffConst.MENTORS_LOADED, mentors: mentors };
    }

    function failure( error ) {
        return { type: staffConst.UNLOADED, error };
    }
}

function loadCareers() {
    return dispatch => {
        staffService.get( roleConst.CAREER )
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( json => {
                if ( json && json.data ) {
                    if ( json.data.length > 0 ) dispatch( career_success( json.data ) );
                } else {
                    dispatch( failure( json ) );
                }
            } );
    }

    function career_success( careers ) {
        return { type: staffConst.CAREERS_LOADED, careers: careers };
    }

    function failure( error ) {
        return { type: staffConst.UNLOADED, error };
    }
}