import { modalConst } from '../_constants/modal.constants';

export function renderModal( modal_props ) {
    return {
        type: modalConst.RENDER,
        modal_props: Object.assign({}, modal_props, {dialogopen: true})
    }
}

export function removeModal() {
    return {
        type: modalConst.REMOVE
    }
}
