import { h, Component, createRef } from 'preact';
import { connect } from 'react-redux';
import cx from 'classnames'
import { renderModal } from '../../_actions/modal.actions';
import { listConst } from '../../_constants/lists.constants';
import { modalConst } from '../../_constants/modal.constants';
import { authConst } from '../../_constants/auth.constants';
import { Card, Icon } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import ContextMenu from './ContextMenu';
import Star from '../star';
import style from './style.scss';

const monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ];

class Listing extends Component {

    constructor( props ) {
        super( props );
        this.state = {
            starred: this.props.listing.starred,
            starredId: this.props.listing.starredId,
            overlayed: false,
            menuopened: false
        };
    }

    componentWillReceiveProps( nextProps, nextState ) {
        if ( nextProps.listing.starred != this.state.starred) {
            this.setState( { starred: nextProps.listing.starred, starredId: nextProps.listing.starredId } );
        }
    }

    editCustomLink = ( ) => {
        let action = '';
        if ( this.props.type === listConst.LEARNING ) {
            action = modalConst.OPEN_LEARNING;
        } else if ( this.props.type === listConst.WORKPLACE ) {
            action = modalConst.OPEN_WORKPLACE;
        } else if ( this.props.type === listConst.EVENT ) {
            action = modalConst.OPEN_EVENT;
        } else if ( this.props.type === listConst.INSPIRATION ) {
            action = modalConst.OPEN_INSPIRATION;
        }

        this.props.dispatch( renderModal( {
            which: action,
            custom: this.props.listing
        } ) );
    }

    deleteCustomLink = (listing) => {
        this.props.deleteCustom(listing.id);
    }
    
    visitlink = ( e ) => {
        e.stopPropagation();
    }

    toggleoverlay = ( e ) => {
        if ( this.props.listing.overlay_desc ) {
            e.preventDefault();
            this.setState( {
                overlayed: !this.state.overlayed
            } );
            return false;
        }
    }

    drawDate = ( incomingdate ) => { /* assumes date format 2012-12-24 */
        var p = incomingdate.split( '-' );
        if ( p.length > 2 ) {
            var x = new Date( p[ 0 ] * 1, p[ 1 ] * 1 - 1, p[ 2 ] * 1 );
            if ( x instanceof Date && !isNaN( x ) && x.getFullYear() === p[ 0 ] * 1 ) {
                return <time className = { style.date_box } datetime = { x.getFullYear() + '-' + ( x.getMonth() + 1 ) + '-' + x.getDate() } > { monthNames[ x.getMonth() ] } < i > { x.getDate() } </i></time >
            }
        }
    }

    render() {

        let l = this.props.listing;
        var tempload = this.props.loading || !l.id;
        var url = '#';
        //if ( this.props.type === listConst.INSPIRATION ) url = '/inspiration/' + l.id;
        if ( this.props.type === listConst.EVENT && !l.custom ) url = '/events/' + l.id;
        if ( this.props.type === listConst.JOBS ) url = '/jobboard/' + l.id;
        if ( ( this.props.type === listConst.WORKPLACE || this.props.type === listConst.JOBS || this.props.type === listConst.LEARNING || ( this.props.type === listConst.EVENT && l.custom ) ) && l.website ) url = l.website;
        if ( this.props.type === listConst.CUSTOM ) url = l.website;
        let bgimage = '/assets/images/bla.png';
        if ( this.props.type === listConst.CUSTOM ){
            bgimage = l.image.data.full_url;
        }else if ( l.image ) {
            bgimage = process.env.PREACT_APP_API_URL+'/assets/'+l.image.id+'?fit=cover&width=600&height=600&quality=80';
        }else if( this.props.type === listConst.JOBS && l.workplace && l.workplace.image ){
            bgimage = process.env.PREACT_APP_API_URL+'/assets/'+l.workplace.image.id+'?fit=cover&width=600&height=600&quality=80';
        }

        var displayurl = ( l.website ) ? l.website.replace( "https://", "" )
            .replace( "http://", "" )
            .replace( "www.", "" ) : '';
        if ( displayurl.length > 25 ) displayurl = displayurl.substring( 0, 24 ) + '...';
        var loadClass = tempload ? cx( style.media, style.loading ) : cx( style.media );
        var overlayClass = this.state.overlayed ? cx( style.overlay, style.open ) : cx( style.overlay );
        var icon = l.type;
        if ( this.props.type === listConst.INSPIRATION && l.icon ) icon = l.icon;
        let button = 'Learn more';
        if ( icon === 'video' ) button = 'Watch';
        let studentOrNotloggedIn = this.props.auth.role === authConst.ROLE_STUDENT || !this.props.auth.loggedIn;
        if( this.props.type === listConst.JOBS ){
          l.sub_title = l.workplace && l.workplace.title ? l.workplace.title : l.workplace_name;;
          l.overlay_desc = ( l.description && l.description.length > 71 ) ? l.description.substring( 0, 70 ) : l.description;
        }

        return (
            <Card classes={{root:style.surround}}>
                {!this.props.nostars && studentOrNotloggedIn && l.custom &&
                    <div>
                        <ContextMenu editCustomLink={this.editCustomLink} deleteCustomLink={this.deleteCustomLink} listing={l} />
                    </div>
                }
                {!this.props.nostars && !l.custom && studentOrNotloggedIn && <div class={'editanchor'}><Star listing={this.props.listing} starred={this.state.starred} starredId={this.state.starredId} type={this.props.type}/></div>}
                
                <div class={style.screen} onClick={this.toggleoverlay}>
                    {tempload && <div class="loadwrap"><div class="lds-ellipsis absol"><div></div><div></div><div></div><div></div></div></div>}
                    {!tempload && this.props.type !== listConst.CUSTOM &&
                        <CardMedia classes={{root:loadClass}} image={bgimage} />
                    }
                    {!tempload && this.props.type === listConst.CUSTOM &&
                        <a target="_blank" href={l.website}><CardMedia classes={{root:loadClass}} image={bgimage} /></a>
                    }
                    {!tempload &&
                        <div class={style.description}>
                            {l.event_date && this.drawDate(l.event_date)}
                            <h5>
                                {this.props.type === listConst.WORKPLACE && <a href={url} target="_blank">{l.title}</a>}
                                {this.props.type !== listConst.WORKPLACE && this.props.type !== listConst.INSPIRATION && <a href={url}>{l.title}</a>}
                                {this.props.type === listConst.INSPIRATION && <span>{l.title}</span>}
                                {l.website && this.props.type != listConst.LEARNING && !this.props.listing.overlay_desc && <sub><a href={l.website} rel="noopener" target="_blank">{displayurl}</a></sub> }
                                {l.sub_title && <sub>{l.sub_title}</sub> }
                            </h5>
                            {l.type && <footer><Icon fontSize={'inherit'}>{icon}</Icon></footer>}
                        </div>
                    }
                    {!tempload && l.overlay_desc && <div class={overlayClass}><div>
                        {l.title && <h6>{l.title}
                            {l.sub_title && <sub>{l.sub_title}</sub> }
                        </h6>}
                        {l.overlay_desc && <p>{l.overlay_desc}</p>}
                        {l.website && <a href={l.website} onClick={this.visitlink} class="MuiButtonBase-root on-green" rel="noopener" target="_blank">{button}</a>}
                    </div></div>}
                </div> 
            </Card>
        );
    }
}

function mapStateToProps( state ) {
    const { auth } = state; //(see loading in auth.reducer)
    return {
        auth
    };
}

export default connect( mapStateToProps )( Listing );