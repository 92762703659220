import { fetchOptions } from '../_helpers/request.helpers';

export const statusService = {
    get
};

function get( role ) {
    let reqOptions = Object.assign( {}, fetchOptions( true ), {
        method: 'GET'
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/status?sort=id', reqOptions );
}
