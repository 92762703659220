import { dashConst } from '../_constants/dash.constants.js'

const dash_initial = {
    tag: '',
    id: ''
}

export function dash( state = dash_initial, action ) {
    switch ( action.type ) {
        case dashConst.UPDATE:
            return action.url_props;
        case dashConst.RESET:
            return dash_initial;
        default:
            return state
    }
}
