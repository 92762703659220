import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { Button, Snackbar, Icon } from '@material-ui/core';

class Alerts extends Component {

    constructor( props ) {
        super( props );
        this.state = {
            queue: [],
            open: false,
            messageInfo: undefined
        };
    }

    componentWillReceiveProps( nextProps ) {
        if(nextProps.memo && nextProps.memo.error && nextProps.memo.message.length > 0){
            let message = nextProps.memo.message;
            this.setState(state => {
                let q = [...state.queue];
                q.push({
                    message,
                    key: new Date().getTime(),
                });
                return {
                    queue: q
                };
            }, () => {
                this.processQueue();
            });
        }
    }

    processQueue = () => {
        if (this.state.queue.length > 0 && !this.state.open) {
            this.setState(state => {
                const m = state.queue[0];
                let t = [...state.queue];
                t.shift();
                return {
                    queue: t,
                    messageInfo: m,
                    open: true
                };
            });
        }
    };


    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState( { open: false} );
    };

    handleExited = () => {
        this.processQueue();
    };


    render() {
        return (
            <>
                {this.state.messageInfo && <Snackbar
                    key={this.state.messageInfo ? this.state.messageInfo.key : undefined}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.open}
                    autoHideDuration={15000}
                    onClose={this.handleClose}
                    TransitionProps={{
                        onExited: () => {
                            this.handleExited();
                        }
                    }}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.messageInfo ? this.state.messageInfo.message : undefined}</span>}
                    action={[
                        <Button key="undo" color="secondary" size="small" onClick={this.handleClose}>
                            <Icon>close</Icon>
                        </Button>
                    ]}
                />}
            </>
        );
    }
}


function mapStateToProps( state ) {
    const { memo } = state;
    return {
        memo
    };
}

export default connect( mapStateToProps )( Alerts );