import { h, Component } from 'preact';
import AddYourOwn from '../../components/addyourown';
import Results from '../../components/results';
import { listConst } from '../../_constants/lists.constants';
import SubLoader from './sections/SubLoader';
import SectorLoader from './sections/SectorLoader';
import style from './style.scss';

export default class Sector extends Component {

    state = {
        ini: false,
        sectornotsub: true,
        sector: {},
        sub: {},
        workplacesini: false
    };

    componentDidMount() {
        this.handleSwitch(this.props.matches);
    }
    
    componentWillReceiveProps(nextProps) {
        this.handleSwitch(nextProps.matches);
    }

    handleSwitch(props){
        if(props.sub){
            this.setState({
                ini: true,
                sectornotsub: false,
                sub: props.sub,
                sector: {},
                workplacesini: false
            });
        }else{
            this.setState({
                ini: true,
                sectornotsub: true,
                sub: {},
                sector: props.tag,
                workplacesini: false
            });
        }
    }

    callback = () => {
        this.setState({
            workplacesini: true
        });
    }

    workplacesLoad = () => {
        
    }

    render() {
        let qp = {
            sort: 'title',
            limit: 12
        }

        return (
            <div class={'content_wrap '+style.sector_wrap}>
                {this.state.ini && !this.state.sectornotsub && <SubLoader sub={this.state.sub} />}
                {this.state.ini && this.state.sectornotsub && <SectorLoader tag={this.state.sector} />}
                {this.state.workplacesini &&
                    <div>
                        <h3>Workplaces in {this.state.sector.title}</h3>
                        <p>Discover workplaces and businesses you might be interested in.</p>
                        <Results type={listConst.WORKPLACE} debounced={true} loadmore={true} params={qp} selectedindustries={[this.state.sector.id]} />
                        
                        <AddYourOwn flip={true}  type={listConst.WORKPLACE} />
                    </div>
                }
            </div>
        );
    }
}
