import { fetchOptions, filterQuery } from '../_helpers/request.helpers';

export const customLinksService = {
    get,
    add,
    patch,
    remove
};


function get( profile_id, type ) {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'GET'
    } );

    let filters = [];
    if( type ) filters.push('{"type":{"_eq":"'+type+'"}}');
    if( profile_id ) filters.push('{"user_created":{"_eq":"'+profile_id+'"}}');
    
    let qs = '?fields=*&sort=-id&' + filterQuery(filters);
    return fetch( process.env.PREACT_APP_API_URL + '/items/custom_links' + qs, reqOptions );
}

function add( link ) {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'POST',
        body: JSON.stringify( link )
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/custom_links', reqOptions );
}

function patch( customLink, custom_id ) {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'PATCH',
        body: JSON.stringify( customLink )
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/custom_links/'+custom_id, reqOptions );
}

function remove( id ) {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'DELETE'
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/custom_links/' + id, reqOptions );
}
