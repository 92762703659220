import AddLink from './modals/AddLink';
import SignIn from './modals/SignIn';
import AddSchool from './modals/AddSchool';
import AddPerson from './modals/AddPerson';
import AddArea from './modals/AddArea';
import AddAction from './modals/AddAction';
import { modalConst } from '../../_constants/modal.constants';


export function map_prompt_to_component( prompt_type ) {
    if ( prompt_type === modalConst.OPEN_SIGNIN ) {
        return SignIn;
    } else if ( prompt_type === modalConst.OPEN_SCHOOL ) {
        return AddSchool;
    } else if ( prompt_type === modalConst.OPEN_STUDENT ) {
        return AddPerson;
    } else if ( prompt_type === modalConst.OPEN_MENTOR ) {
        return AddPerson;
    } else if ( prompt_type === modalConst.OPEN_CAREER ) {
        return AddPerson;
    } else if ( prompt_type === modalConst.OPEN_AREA ) {
        return AddArea;
    } else if ( prompt_type === modalConst.OPEN_ACTION ) {
        return AddAction;
    } else {
        return AddLink;
    }
}
