import { Link } from 'preact-router';
import MenuLinks from '../../_helpers/MenuLinks.js';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    page_footer: {
        fontFamily: 'Gotham Rounded A, Gotham Rounded B',
        backgroundColor: theme.palette.primary.main,
        '& a': {
            textDecoration: 'none'
        },
        ['@media print']: {
            display: 'none'
        }   
    },
    logo_footer: {
        height: 52
    },
    footer_column: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            flexDirection: 'row',
            textAlign:'center'
        }
    },
    section_header: {
        textTransform: 'uppercase',
        fontSize: 13,
        fontWeight: 500,
        color: theme.palette.secondary.main
    },
    section_content: {
        paddingLeft: 0,
        fontSize: 12,
        '& li': {
            lineHeight: '170%',
            padding: '7px 0',
            listStyleType: 'none',
            '& a': {
                color:'white'
            }
        }
    },
    foot_foot: {
        backgroundColor: theme.palette.primary.dark,
        '& div': {
            color: 'white',
            fontSize: 10,
            padding: '2em',
            textAlign: 'center',
            width: '100%',
            '& span': {
                paddingRight: '1em'
            }
        },
        '& a': {
            color: 'white'
        }
    }
}));


export default function Footer() {

    const style = useStyles();

    return (
        <footer class={style.page_footer}>
            <div class="padout" style={{paddingTop:'16px'}}>
                <Grid container spacing={4}>
                    <Grid item md={3} lg={2} classes={{root:style.footer_column}}>
                        <img src="/assets/images/MyNextMove-Logo.png"  srcset="/assets/images/MyNextMove-Logo@2x.png 2x,/assets/images/MyNextMove-Logo.png 1x" alt="logo" class={style.logo_footer} title="My Next Move"/>
                    </Grid>
                    <Grid item classes={{root:style.footer_column}}>
                        <Link class={style.section_header} href={MenuLinks.Home} title="" aria-label="Home">My Next Move</Link>
                        <ul class={style.section_content}> 
                            <li><Link href={MenuLinks.Events} title="" aria-label="Events">Events</Link></li>
                            <li><Link href={MenuLinks.Workplaces} title="" aria-label="Workplaces">Workplaces</Link></li>
                            <li><Link href={MenuLinks.StayLearning} title="" aria-label="Stay Learning">Stay Learning</Link></li>
                            <li><Link href={MenuLinks.Inspiration} title="" aria-label="Inspiration &amp; Learning">Inspiration &amp; Learning</Link></li>
                        </ul>
                    </Grid>
                    <Grid item md={2} classes={{root:style.footer_column}}>
                        <Link class={style.section_header} href={MenuLinks.Workplaces} title="" aria-label="Workplaces">Workplaces</Link>
                        <ul class={style.section_content}>
                            <li><Link href={MenuLinks.Workplaces} title="" aria-label="Discover Workplaces">Discover Workplaces</Link></li>
                            <li><Link href={MenuLinks.IndustrySectors} title="" aria-label="Industry Sectors">Industry Sectors</Link></li>
                            <li><Link href={MenuLinks.EmployersGetInvolved} title="" aria-label="Employers - Get Involved">Employers - Get Involved</Link></li>
                            {<li><Link href={MenuLinks.BecomeAMentor} title="" aria-label="Become a mentor">Become a mentor</Link></li>}
                        </ul>
                    </Grid>
                    <Grid item md={2} classes={{root:style.footer_column}}>
                        <Link class={style.section_header} href={MenuLinks.StayLearning} title="" aria-label="Stay Learning">Stay Learning</Link>
                    </Grid>
                    <Grid item md={2} classes={{root:style.footer_column}}>
                        <Link class={style.section_header} href={MenuLinks.Inspiration} title="" aria-label="Inspiration">Inspiration</Link>
                    </Grid>
                </Grid>
            </div>
            <Grid container classes={{root:style.foot_foot}} alignContent='center'>
                <div>
                    <span>Copyright &copy; 2018</span> 
                    <Link href={MenuLinks.Privacy} title="" aria-label="Privacy Policy">Privacy Policy</Link>
                    &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                    <Link href={MenuLinks.About} title="" aria-label="About this site">About this site</Link>
                    &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                    <Link href={MenuLinks.GetInTouch} title="" aria-label="Get in touch">Get in touch</Link>
                </div>
            </Grid>
        </footer>
    );
}
