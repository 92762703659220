import { fetchOptions } from '../_helpers/request.helpers';
import { roleConst } from '../_constants/person.constants';

export const staffService = {
    get
};

function get( role ) {
    let reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'GET'
    } );
    var collection = 'career_advisors'
    if ( role === roleConst.MENTOR ) collection = 'mentors'

    return fetch( process.env.PREACT_APP_API_URL + '/items/' + collection + '?sort=first_name&fields=*,user_created.email', reqOptions );
}
