import { fetchOptions } from '../_helpers/request.helpers';

export const schoolService = {
    get,
    add,
    remove
};

function get() {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'GET'
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/school?sort=name', reqOptions );
}

function add( name ) {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'POST',
        body: JSON.stringify( { name: name } )
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/school', reqOptions );
}

function remove( id ) {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'DELETE'
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/school/' + id, reqOptions );
}
