import { Component } from 'preact';
import { connect } from 'react-redux';
import { route } from 'preact-router';
import linkState from 'linkstate';
import { Select, TextField, Button, LinearProgress, FormControl, Grid, FilledInput, InputLabel, Checkbox } from '@material-ui/core';
import MenuLinks from '../../../_helpers/MenuLinks';
import Message from '../../message';
import { memoActions } from '../../../_actions/memo.actions';
import { staffActions } from '../../../_actions/staff.actions';
import { modalConst } from '../../../_constants/modal.constants';
import { personConst, roleConst } from '../../../_constants/person.constants';
import { authConst } from '../../../_constants/auth.constants';
import { fetchConst } from '../../../_constants/fetch.constants';
import { dashboard } from '../../../_constants/lists.constants';
import { profileActions } from '../../../_actions/profile.actions';
import { statusService } from '../../../_services/status.service';
import { handleCatchError } from '../../../_helpers/request.helpers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { removeModal } from '../../../_actions/modal.actions';

class AddPerson extends Component {

    constructor( props ) {
        super( props );

        this.state = {
            editing: false,
            editing_id: 0,
            processing: false,
            loadingdata: true,
            statuslist: [],
            first_name: '',
            last_name: '',
            email: '',
            old_email: '',
            school: '',
            school_year: '',
            career: '',
            mentor: '',
            status: '',
            subjects: '',
            interests: '',
            invite_status: false
        };
    }

    refCollection = ( props ) => {
        if ( props.modal.modal_props.which === modalConst.OPEN_MENTOR ) {
            return props.mentors;
        } else if ( props.modal.modal_props.which === modalConst.OPEN_CAREER ) {
            return props.careers;
        }
        return [];
    }

    staffProfile = ( id, collection ) => {
        if ( id && collection ) {
            var sch = collection.filter( obj => {
                return obj.id == id;
            } );
            if ( sch.length > 0 ) {
                return sch[ 0 ];
            }
        }
        return null;
    }

    componentDidMount() {
        
        if ( (this.props.modal.modal_props.which === modalConst.OPEN_CAREER || this.props.modal.modal_props.which === modalConst.OPEN_MENTOR) ){
            if( this.props.modal.modal_props.editing_id > 0 ) {
                var p = this.staffProfile(this.props.modal.modal_props.editing_id, this.refCollection( this.props ));
                this.setState( {
                    editing: true,
                    loadingdata: false,
                    editing_id: p.id,
                    first_name: p.first_name,
                    last_name: p.last_name,
                    email: p.user_created.email,
                    old_email: p.user_created.email
                } );
            } else {
                this.setState( {
                    loadingdata: false
                 } );
            }
        }else if ( this.props.modal.modal_props.which === modalConst.OPEN_STUDENT ) {
            if ( this.props.modal_props.profile && this.props.modal_props.profile.id ) {
                this.setState( {
                    editing: true
                } );
            }
            statusService.get()
                .then( ( response ) => response.json(), error => {
                    this.props.dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                    this.props.dispatch( failure( error ) );
                } )
                .then( ( json ) => {
                    // student does not have permissions over certain fields.
                    if ( json.data ) {
                        // Load student
                        if ( this.props.modal_props.profile && this.props.modal_props.profile.id ) {
                            let p = this.props.modal_props.profile;
                            this.setState( {
                                statuslist: json.data,
                                loadingdata: false,
                                editing_id: p.id,
                                first_name: p.first_name,
                                last_name: p.last_name,
                                email: p.user_created.email,
                                old_email: p.user_created.email,
                                school_year: p.school_year,
                                status: ( p.status ) ? p.status.id : 0,
                                subjects: ( p.subjects ) ? p.subjects.join( ',' )
                                    .replace( /^, |, $/g, '' ) : '',
                                interests: ( p.interests ) ? p.interests.join( ',' )
                                    .replace( /^, |, $/g, '' ) : '',
                                school: ( this.props.auth.role === authConst.ROLE_ADMIN && p.school ) ? p.school.id : 0,
                                career: ( this.props.auth.role === authConst.ROLE_ADMIN && p.school_contact ) ? p.school_contact.id : 0,
                                mentor: ( this.props.auth.role === authConst.ROLE_ADMIN && p.mentor) ? p.mentor.id : 0
                            } );
                        }else{
                            this.setState( {
                                statuslist: json.data,
                                loadingdata: false
                            } );
                        }
                    }else{
                        this.setState( {
                            loadingdata: false
                        } );
                    }
                } );
        }
    }


    handleSubmitViaEnter = ( e ) => {
        if ( e.key === 'Enter' ) {
            this.handleSubmit( e );
        }
    }

    handleSubmit = ( e ) => {
        e.preventDefault();
        this.processForm();
    }

    handleChange = name => event => {
        this.setState( {
            [name]: event.target.value,
        } );
    };

    processForm = () => {
        this.setState( { processing: true }, () => {
            const { first_name, last_name, email, school, school_year, career, mentor, status, subjects, interests } = this.state;
            
            if ( first_name && last_name && email) {
                var person_type = '';
                var profile = {
                    first_name: first_name,
                    last_name: last_name
                };
                if ( this.props.modal.modal_props.which === modalConst.OPEN_STUDENT ) {
                    person_type = personConst.STUDENT;
                    profile.school_year = school_year;
                    profile.subjects = subjects.split( ',' );
                    profile.interests = interests.split( ',' );
                    if ( status ) profile.status = status

                    if ( this.props.auth.role === authConst.ROLE_CAREER_ADVISORS ) {
                        profile.school_contact = this.props.profile.id;
                        profile.school = this.props.profile.school;
                    } else if ( this.props.auth.role === authConst.ROLE_ADMIN ) {
                        if ( mentor ) profile.mentor = mentor
                        if ( school ) profile.school = school
                        if ( career ) profile.school_contact = career
                    }
                } else if ( this.props.modal.modal_props.which === modalConst.OPEN_MENTOR ) {
                    person_type = personConst.MENTOR;
                } else if ( this.props.modal.modal_props.which === modalConst.OPEN_CAREER ) {
                    person_type = personConst.CAREER;
                    if ( school != '0' ) profile.school = school
                }
                profile.upgrade = '1';
                profile.email = email;
                let t = this;
                if ( this.state.editing ) {
                    let emailchange = ( this.state.old_email !== email && this.props.auth.role === authConst.ROLE_ADMIN ) ? email : null;
                    if ( this.props.modal.modal_props.which === modalConst.OPEN_STUDENT ) {
                        this.props.dispatch( profileActions.patchStudentProfile( this.state.editing_id, profile, emailchange ) )
                            .then( json => {
                                window.location.reload();
                                t.props.close( t.dialogue );
                            } );
                    } else if ( this.props.auth.role === authConst.ROLE_ADMIN && (person_type === personConst.CAREER || person_type === personConst.MENTOR)) {
                        this.props.dispatch( profileActions.patchProfile( person_type, this.state.editing_id, profile, emailchange ) )
                            .then( json => {
                                t.props.close( t.dialogue );
                            } );
                    }
                //This is new
                }else if ( this.props.modal.modal_props.which === modalConst.OPEN_STUDENT ) {
                    profile.invite_status = this.state.invite_status ? '1': '0';
                    this.props.dispatch( profileActions.createStudentAdmin( profile ) )
                        .then( ( json ) => {
                            t.setState({
                                processing: false
                            });
                            if ( t.props.auth.role == authConst.ROLE_CAREER_ADVISORS ) {
                                t.props.dispatch( profileActions.load( personConst.CAREER, t.props.profile.id ) );
                            }
                            if ( json.data && json.data.id ){
                                route(MenuLinks.Dashboard + '/' + person_type + '/' + json.data.id, true);
                                t.props.close( t.dialogue );
                            }else{
                                t.props.dispatch( { type: profileConst.FAILURE, object: json.data } );
                            }
                        })
                        .catch ( ( e ) => {
                            handleCatchError( t.props.dispatch );
                            t.setState({
                                processing: false
                            });
                        } );
                } else {
                    this.props.dispatch( profileActions.createStaffAdmin( profile, person_type ) )
                        .then( ( json ) => {
                            t.setState({
                                processing: false
                            });
                            if ( person_type == personConst.CAREER ) {
                                person_type = dashboard.CAREERS;
                                t.props.dispatch( staffActions.loadCareers() );
                            } else if ( person_type == personConst.MENTOR ) {
                                t.props.dispatch( staffActions.loadMentors() );
                            }
                            if( json.data && json.data.id ){
                                route(MenuLinks.Dashboard +'/'+person_type+'/'+json.data.id, true);
                            }
                            t.props.close( t.dialogue );
                        })
                        .catch ( ( e ) => {
                            handleCatchError( t.props.dispatch );
                            t.setState({
                                processing: false
                            });
                        } );
                }
                
            } else {
                this.setState( { 'message': 'Please supply a first and last name and email', 'error': true } );
            }
        } );
    }

    closeDialogue = ( e ) => {
        this.props.dispatch( memoActions.clear() );
        setTimeout( () => {
            this.props.dispatch( removeModal() )
        }, 500 )
    }

    render( {}, { processing, first_name, last_name, status, email, school, school_year, career, mentor, subjects, interests, avatar } ) {
        
        var title, button;

        if ( this.props.modal.modal_props.which === modalConst.OPEN_STUDENT ) {
            title = ( this.state.editing ) ? "Editing Student" : "Add Student";
            button = ( this.state.editing ) ? "Update" : "Add student";
            if ( this.props.auth.role !== authConst.ROLE_CAREER_ADVISORS && this.props.auth.role !== authConst.ROLE_ADMIN ) title = "Your profile";
        } else if ( this.props.modal.modal_props.which === modalConst.OPEN_MENTOR ) {
            title = ( this.state.editing ) ? "Editing Mentor" : "Add Mentor";
            button = ( this.state.editing ) ? "Update" : "Add a mentor";
        } else if ( this.props.modal.modal_props.which === modalConst.OPEN_CAREER ) {
            title = ( this.state.editing ) ? "Editing Career Advisor" : "Add Career Advisor";
            button = ( this.state.editing ) ? "Update" : "Add a career advisor";
        }
        if( this.state.loadingdata ){
            title = 'Loading...';
        }
        let edit_user = this.props.auth.role === authConst.ROLE_ADMIN || this.props.auth.role === authConst.ROLE_CAREER_ADVISORS;

        return (
            <Dialog className="whiter" open={this.props.open} onCancel={this.closeDialogue} fullWidth maxWidth="md">
                <form onSubmit={this.handleSubmit} onKeyPress={this.handleSubmitViaEnter} autocomplete="off">
                    <DialogTitle>{title}</DialogTitle>
                    {processing && <LinearProgress indeterminate />}
                    <DialogContent>
                        {this.state.message && !this.props.memo.message &&
                            <Message message={this.state.message} error={this.state.error} />
                        }
                        {this.props.memo.message &&
                            <Message message={this.props.memo.message} error={this.props.memo.error} />
                        }
                        {this.state.loadingdata && <div class="lds-ripple"><div></div><div></div></div>}
                        {!this.state.loadingdata && <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField value={first_name} onInput={linkState(this, 'first_name')} required={true} name="first_name" label={'First name...'} dense={true} fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField value={last_name} onInput={linkState(this, 'last_name')} required={true} name="last_name" label={'Last name...'} dense={true} fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                {edit_user && <TextField value={email} onInput={linkState(this, 'email')} required={true} name="email" label={'Email...'} dense={true} fullWidth />}
                                {!edit_user && <span>Email: <a href={'mailto:' + email}>{email}</a></span>}
                                {!this.state.editing && !this.props.modal.modal_props.which === modalConst.OPEN_STUDENT && <p class="fineprint">An invitation will be emailed with a link valid for 48 hours.</p>}
                            </Grid>


                            { this.props.modal.modal_props.which === modalConst.OPEN_STUDENT && !this.state.editing && <Grid item xs={6} style={{paddingTop:'18px'}}>
                                <Checkbox
                                    checked={this.state.invite_status}
                                    onChange={this.handleChange('invite_status')}
                                    value="invite_status"
                                    color="primary"
                                    inputProps={{
                                    'aria-label': 'secondary checkbox',
                                    }}
                                /> Send invite immediately
                            </Grid>}
                        </Grid> }
                        { !this.state.loadingdata && this.props.modal.modal_props.which === modalConst.OPEN_CAREER &&
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl variant="filled" fullWidth={true} margin={'dense'} className="selectNative">
                                        <InputLabel htmlFor="school">
                                            Select school
                                        </InputLabel>
                                        <Select 
                                            native fullWidth 
                                            value={school} 
                                            onChange={this.handleChange('school')}
                                            input={<FilledInput name="school" id="school" />}
                                        >
                                            {this.props.schools.map(item => 
                                                <option value={item.id}>{item.name}</option>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        }
                        { !this.state.loadingdata && this.props.modal.modal_props.which === modalConst.OPEN_STUDENT &&
                            <Grid container spacing={2}>
                                {this.props.auth.role === authConst.ROLE_ADMIN && 
                                    <Grid item xs={6}>
                                        <FormControl variant="filled" fullWidth={true} margin={'dense'} className="selectNative">
                                            <InputLabel htmlFor="school">
                                                Select school
                                            </InputLabel>
                                            <Select 
                                                native fullWidth 
                                                value={school} 
                                                onChange={this.handleChange('school')}
                                                input={<FilledInput name="school" id="school" />}
                                            >
                                                {this.props.schools.map(item => 
                                                    <option value={item.id} selected={item.id === this.state.school}>{item.name}</option>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                {this.props.auth.role === authConst.ROLE_ADMIN && 
                                    <Grid item xs={6}>
                                        <FormControl variant="filled" fullWidth={true} margin={'dense'} className="selectNative">
                                            <InputLabel htmlFor="career">
                                                Select career advisor
                                            </InputLabel>
                                            <Select 
                                                native fullWidth 
                                                value={career} 
                                                onChange={this.handleChange('career')}
                                                input={<FilledInput name="career" id="career" />}
                                            >
                                                <option value="0">Don't set now</option>
                                                {this.props.careers.map(item => 
                                                    <option value={item.id} selected={item.id === this.state.career}>{item.first_name} {item.last_name}</option>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                {this.props.auth.role === authConst.ROLE_ADMIN && 
                                    <Grid item xs={6}>
                                        <FormControl variant="filled" fullWidth={true} margin={'dense'} className="selectNative">
                                            <InputLabel htmlFor="mentor">
                                                Select mentor
                                            </InputLabel>
                                            <Select 
                                                native fullWidth 
                                                value={mentor} 
                                                onChange={this.handleChange('mentor')}
                                                input={<FilledInput name="mentor" id="mentor" />}
                                            >
                                                <option value="0">Don't set now</option>
                                                {this.props.mentors.map(item => 
                                                    <option value={item.id} selected={item.id === this.state.mentor}>{item.first_name} {item.last_name}</option>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={6}>

                                    <FormControl variant="filled" fullWidth={true} margin={'dense'} className="selectNative">
                                        <InputLabel htmlFor="status">
                                            Highest qualification
                                        </InputLabel>
                                        <Select
                                            native
                                            value={status}
                                            onChange={this.handleChange('status')}
                                            input={<FilledInput name="status" id="status" />}
                                        >
                                            <option value="0">Don't set now</option>
                                            {this.state.statuslist.map(item => 
                                                <option value={item.id} selected={item.id === this.state.status}>{item.title}</option>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField value={subjects} onInput={linkState(this, 'subjects')} required={false} name="subjects" label={'Subjects (comma seperated)'} dense={true} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField value={interests} onInput={linkState(this, 'interests')} required={false} name="interests" label={'Interests (comma seperated)'} dense={true} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField value={school_year} onInput={linkState(this, 'school_year')} required={false} name="school_year" label={'Year level'} dense={true} fullWidth />
                                </Grid>
                            </Grid>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialogue}>Cancel</Button>
                        <Button variant="outlined"  type="submit">{button}</Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

function mapStateToProps( state ) {
    const { modal, memo, schools, careers, mentors, auth, profile } = state;
    return {
        auth,
        modal,
        memo,
        schools,
        careers,
        mentors,
        profile
    };
}

export default connect( mapStateToProps )(  AddPerson );
