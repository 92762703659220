import { fetchOptions } from '../_helpers/request.helpers';

export const areasService = {
    get,
    add,
    patch,
    remove
};

function get( profile_id ) {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'GET'
    } );
    let filter = ( profile_id ) ? '?filter={"user_created":{"_eq":"'+profile_id+'"}}' : '';
    return fetch( process.env.PREACT_APP_API_URL + '/items/areas' + filter, reqOptions );
}

function add( title, description, student_id ) {
    var area = { title: title, description: description };
    if ( student_id ) area.student_id = student_id;

    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'POST',
        body: JSON.stringify( area )
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/areas', reqOptions );
}

function patch( areaPost, area_id ) {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'PATCH',
        body: JSON.stringify( areaPost )
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/areas/'+area_id, reqOptions );
}

function remove( id ) {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'DELETE'
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/areas/' + id, reqOptions );
}
