import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducer from '../_reducers';

export const store = createStore(
    reducer,
    applyMiddleware(
        thunkMiddleware
    )
);
