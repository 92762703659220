import { staffConst } from '../_constants/staff.constants';

const initialState = [];

export function mentors( state = initialState, action ) {
    switch ( action.type ) {
        case staffConst.MENTORS_LOADED:
            return action.mentors;
        case staffConst.UNLOADED:
            return initialState;
        case staffConst.MENTOR_PATCHED:
            let n = state.slice();
            let index = n.findIndex(({ id }) => id === action.profile.id);
            n[index] = action.profile;
            return n;
        case staffConst.MENTORS_REMOVED:
            let tempArray = state.filter(obj => {
                return obj.id != action.id;
            });
            return tempArray;
        default:
            return state;
    }
}

export function careers( state = initialState, action ) {
    switch ( action.type ) {
        case staffConst.CAREERS_LOADED:
            return action.careers;
        case staffConst.UNLOADED:
            return initialState;
        case staffConst.CAREERS_PATCHED:
            let n = state.slice();
            let index = n.findIndex(({ id }) => id === action.profile.id);
            n[index] = action.profile;
            return n;
        case staffConst.CAREERS_REMOVED:
            let tempArray = state.filter(obj => {
                return obj.id != action.id;
            });
            return tempArray;
        default:
            return state;
    }
}
