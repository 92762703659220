import { Component } from 'preact';
import { connect } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, LinearProgress, makeStyles } from '@material-ui/core';
import Message from '../../message';
import linkState from 'linkstate';
import { schoolsActions } from '../../../_actions/schools.actions';
import { removeModal } from '../../../_actions/modal.actions';
import { memoActions } from '../../../_actions/memo.actions';

class AddSchool extends Component {

    constructor( props ) {
        super( props );

        this.openDialogue = () => {
            // Focus the text input using the raw DOM API
            if ( this.dialogue ) this.dialogue.MDComponent.show();
        };
        this.state = {
            processing: false,
            name: ''
        };
    }

    componentDidMount() {
        // autofocus the input on mount
        this.openDialogue();
    }

    handleSubmitViaEnter = ( e ) => {
        if ( e.key === 'Enter' ) {
            this.handleSubmit( e );
        }
    }

    handleSubmit = ( e ) => {
        e.preventDefault();
        const { name } = this.state;

        if ( name ) {
            this.setState( { processing: true, message: '', error: false }, () => {
                const { dispatch } = this.props;
                dispatch( schoolsActions.add( name ) );
            } );
        } else {
            this.setState( { message: 'Please supply a name', error: true } );
        }
    }

    closeDialogue = ( e ) => {
        this.props.dispatch( memoActions.clear() );
        setTimeout( () => {
            this.props.dispatch( removeModal() )
        }, 500 )
    }

    render( {}, { name, processing } ) {
        
        return (
            <Dialog className="whiter" open={this.props.open} onCancel={this.closeDialogue} fullWidth maxWidth="md">
                <form onSubmit={this.handleSubmit} onKeyPress={this.handleSubmitViaEnter} autocomplete="off">
                    <DialogTitle>Add School</DialogTitle>
                    {processing && <LinearProgress indeterminate />}
                    <DialogContent>
                        {this.state.message && !this.props.memo.message &&
                            <Message message={this.state.message} error={this.state.error} />
                        }
                        {this.props.memo.message &&
                            <Message message={this.props.memo.message} error={this.props.memo.error} />
                        }
                        <TextField value={name} onInput={linkState(this, 'name')} name="name" label={'School name...'} dense={true} fullWidth/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialogue}>Cancel</Button>
                        <Button variant="outlined" type="submit">Add school</Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

function mapStateToProps( state ) {
    const { memo, modal } = state;
    return {
        modal,
        memo
    };
}

export default connect( mapStateToProps )( AddSchool );
