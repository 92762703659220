import { authConst } from '../_constants/auth.constants';

let token = ( typeof window !== 'undefined' ) ? localStorage.getItem( 'token' ) : undefined;
const initialState = token ? { loggedIn: true } : {};

export function auth( state = initialState, action ) {
    switch ( action.type ) {
        case authConst.AUTH_REQUEST:
            return {
                loggingIn: true
            };
        case authConst.AUTH_SUCCESS:
            return Object.assign( {}, state, {
                loggedIn: true
            } );
        case authConst.AUTH_FAILURE:
            return {
                loggingIn: false
            };
        case authConst.SIGNOUT:
            return {
                loggedIn: false,
                role: null,
                id: null,
                email: null
            };
        case authConst.USER_ROLE_SUCCESS:
            return Object.assign( {}, state, {
                role: action.profile.role.name,
                id: action.profile.id,
                email: action.profile.email
            } );
        case authConst.SIGNIN_FINISHED:
            return Object.assign( {}, state, {
                loggingIn: false
            } );
        default:
            return state;
    }
}
