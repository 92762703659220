import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { removeModal } from '../../_actions/modal.actions';
import { map_prompt_to_component } from './helpers';
import { memoActions } from '../../_actions/memo.actions';

class ModalRoot extends Component {

    handleClickClose = ( d ) => {
        this.props.dispatch( memoActions.clear() );
        setTimeout( () => {
            this.timeOutClose( d )
        }, 500 )
    };

    /* no callback on material components close animation, allows animation to play */
    timeOutClose = ( d ) => {
        this.props.dispatch( removeModal() );
    }

    render() {

        // Check if any Redux action was sent, if so check modal props
        if (!this.props.type) {
            return null;
        }
        const SpecificModal = map_prompt_to_component( this.props.modal_props.which );
        return (            
            <SpecificModal open={this.props.modal_props.dialogopen} modal_props = { this.props.modal_props } close={this.handleClickClose}/>
        );
    }
}

// Replaced connect(state => state.modal) with this 
// or how is this sent to AddLink??
const mapStateToProps = function ( state ) {
    return state.modal;
}

export default connect( mapStateToProps )( ModalRoot )
