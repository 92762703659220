import { Component } from 'preact';
import { connect } from 'react-redux';
import { TextField, Button, LinearProgress, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Message from '../../message';
import linkState from 'linkstate';
import { memoActions } from '../../../_actions/memo.actions';
import { actionsActions } from '../../../_actions/actions.actions';
import { removeModal } from '../../../_actions/modal.actions';

class AddAction extends Component {

    constructor( props ) {
        super( props );

        this.state = {
            editing_id: 0,
            processing: false,
            title: '',
            date: '',
            description: '',
            responsible: '',
            entity: '',
            area: ''
        };
    }

    componentDidMount() {
        this.setState( {
            area: this.props.modal_props.focus_area,
            editing_id: this.props.modal_props.action_id
        }, () => {
            if(this.props.modal_props.action_id){
                var tempAction = this.props.actions.filter(obj => obj.id === this.state.editing_id)[0];
                this.setState( {
                    editing_id: this.props.modal_props.action_id,
                    title: tempAction.title,
                    date: tempAction.custom_due,
                    description: tempAction.description,
                    responsible: tempAction.person_responsible,
                    entity: tempAction.entity,
                    area: tempAction.area
                } );
            }
        } );
        
    }

    handleSubmitViaEnter = ( e ) => {
        if ( e.key === 'Enter' ) {
            this.handleSubmit( e );
        }
    }

    handleSubmit = ( e ) => {
        e.preventDefault();
        const { title, date, description, responsible, entity } = this.state;

        if ( title && description && responsible && entity ) {
            this.setState( { processing: true, message: '', error: false }, () => {
                const { dispatch } = this.props;
                var actionPost = {
                    title: title,
                    custom_due: date,
                    description: description,
                    person_responsible: responsible,
                    entity: entity
                };
                if ( this.props.modal_props.action_id ) {
                    dispatch( actionsActions.patch( actionPost, this.props.modal_props.action_id ) );
                } else {
                    actionPost.area = this.state.area;
                    actionPost.student_id = this.props.dash.id;
                    dispatch( actionsActions.add( actionPost ) );                
                }
            } );
        } else {
            this.setState( { message: 'Please supply all fields', error: true } );
        }
    }

    closeDialogue = ( e ) => {
        this.props.dispatch( memoActions.clear() );
        setTimeout( () => {
            this.props.dispatch( removeModal() )
        }, 500 )
    }

    render( {}, { title, date, description, responsible, entity, processing } ) {
        let actionText = ( this.state.editing_id ? 'Save' : 'Create' ) + ' Action';
        let titleText = ( this.state.editing_id ? 'Edit' : 'Add' ) + ' Action';

        return (
            <Dialog className="whiter" open={this.props.modal_props.dialogopen} onCancel={this.closeDialogue} fullWidth maxWidth="md">
                <form onSubmit={this.handleSubmit} onKeyPress={this.handleSubmitViaEnter} autocomplete="off">
                    <DialogTitle>{titleText}</DialogTitle>
                    {processing && <LinearProgress indeterminate />}
                    <DialogContent>
                        {this.state.message && !this.props.memo.message &&
                            <Message message={this.state.message} error={this.state.error} />
                        }
                        {this.props.memo.message &&
                            <Message message={this.props.memo.message} error={this.props.memo.error} />
                        }
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField value={title} onInput={linkState(this, 'title')} name="title" label={'Action step...'} dense={true} fullWidth/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField value={date} onInput={linkState(this, 'date')} name="date" label={'Date'} dense={true} fullWidth/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField value={description} onInput={linkState(this, 'description')} name="description" label={'Description'} dense={true} fullWidth/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField value={responsible} onInput={linkState(this, 'responsible')} name="responsible" label={'Person Responsible'} dense={true} fullWidth/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField value={entity} onInput={linkState(this, 'entity')} name="entity" label={'Entity Responsible'} dense={true} fullWidth/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialogue}>Cancel</Button>
                        <Button variant="outlined" type="submit">{actionText}</Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

function mapStateToProps( state ) {
    const { memo, modal, actions, dash } = state;
    return {
        modal,
        memo,
        actions,
        dash
    };
}

export default connect( mapStateToProps )( AddAction );
