import { options, Component } from 'preact';
import { Provider } from 'react-redux';
import { Router } from 'preact-router';
import { authActions } from '../_actions/auth.actions';
import { store } from '../_helpers/store';
import Header from './header';
import Footer from './footer';
import MenuLinks from '../_helpers/MenuLinks';
import Alerts from './alerts';
import ModalRoot from './modals';
import Login from '../routes/login';
import Launch from '../routes/launch';
import Home from '../routes/home';
import About from '../routes/about';
import Privacy from '../routes/privacy';
import Workplaces from '../routes/workplaces';
import NotFound from '../routes/404';
import Forgot from '../routes/forgot';
import Reset from '../routes/reset-password';
import Invited from '../routes/accept-invite';
import Events from '../routes/events';
import GetInTouch from '../routes/getintouch';
import GetInvolved from '../routes/getinvolved';
import Mentoring from '../routes/mentoring';
import StayLearning from '../routes/staylearning';
import Inspiration from '../routes/inspiration';
import SingleEvent from '../routes/events/SingleEvent';
import Account from '../routes/account';
import Sector from '../routes/industrysectors/sector';
import SectorHome from '../routes/industrysectors';
import Dashboard from '../routes/dashboard';
import TransitionPlan from '../routes/transitionplan';
import ListAJob from '../routes/listajob';
import JobBoard from '../routes/jobboard';
import Job from '../routes/job';

const old = options.vnode;
options.vnode = vnode => {
    if (vnode.type && (vnode.type._forwarded || vnode.type.o) === true) {
        vnode.type.prototype.isReactComponent = true;
    }
    if (old) old(vnode);
};

let stayloggedIn;

export default class App extends Component {

    handleRoute = e => {
        this.currentUrl = e.url;
        if ( typeof window !== 'undefined' ) window.scrollTo(0, 0);
    };
    

    componentDidMount() {
        if ( typeof window !== 'undefined' && localStorage.hasOwnProperty( 'token' ) ) {
            // get the key's value from localStorage
            var token = localStorage.getItem( 'token' );
            store.dispatch( authActions.userAndRole( token, false ) );
        }
        //TODO expire refresh on 401 Unauthorized -> {"error":{"code":102,"message":"Expired token"}}
        stayloggedIn = setInterval( () => store.dispatch( authActions.refreshtoken() ), 3000000 );
    }

    componentWillUnmount() {
        clearInterval( stayloggedIn );
    };

    render() {
        return (
            <Provider store={store}>
                <div id="app">
                    <Header />
                    <ModalRoot />
                    <Router onChange={this.handleRoute}>
                        <Home path={MenuLinks.Home} />
                        <About path={MenuLinks.About} />
                        <Privacy path={MenuLinks.Privacy} />
                        <Workplaces path={MenuLinks.Workplaces} />
                        <Login path={MenuLinks.Login} />
                        <Launch path={MenuLinks.Launch} />
                        <Forgot path={MenuLinks.Forgot} />
                        <Reset path={MenuLinks.Reset} />
                        <Invited path={MenuLinks.Invited} />
                        <SingleEvent path={MenuLinks.Events+'/:id'} />
                        <Events path={MenuLinks.Events} />
                        <GetInTouch path={MenuLinks.GetInTouch} />
                        <GetInvolved path={MenuLinks.EmployersGetInvolved} />
                        <Mentoring path={MenuLinks.BecomeAMentor} />
                        <StayLearning path={MenuLinks.StayLearning} />
                        <Inspiration path={MenuLinks.Inspiration} />
                        <Account path={MenuLinks.Account} />
                        <SectorHome path={MenuLinks.IndustrySectors} />
                        <Sector path={MenuLinks.IndustrySectors+'/:tag/:sub?'} />
                        <Dashboard path={MenuLinks.Dashboard+'/:tag/:id?'} />
                        <TransitionPlan path={MenuLinks.TransitionPlan} />
                        <TransitionPlan path={MenuLinks.Dashboard+'/:tag/:id'+MenuLinks.TransitionPlan} />
                        <Dashboard path={MenuLinks.Dashboard} />
                        <ListAJob path={MenuLinks.ListAJob} />
                        <JobBoard path={MenuLinks.JobBoard} />
                        <Job path={MenuLinks.JobBoard+'/:id'} />
                        <NotFound default />
                    </Router>
                    <Footer />
                    <Alerts />
                </div>
            </Provider>
        );
    }
}
