import { Component } from 'preact';
import { connect } from 'react-redux';
import linkState from 'linkstate';
import { withStyles, makeStyles, Icon, TextField, Button, LinearProgress } from '@material-ui/core';
import { memoActions } from '../../_actions/memo.actions';
import { authConst } from '../../_constants/auth.constants';
import { ratingService } from '../../_services/rating.service';


const useStyles = makeStyles(theme => ({
    
    startoggle: {
        flexGrow: 1
    },
    ratingform: {
        backgroundColor: '#1d3956',
        padding: '3em 0 2em 0',
        '& p': {
            color: '#FFF'
        },
        textAlign: 'center',
    },
    stars: {
        color: '#FFF',
        fontSize: '2em',
        paddingTop: '0.8rem',
        '& span': {
            letterSpacing: '10px',
        },
        '& a': {
            color: 'inherit',
            textDecoration: 'none',
        }
    },
    commentbox: {
        
    },
    buffer: {
        maxWidth: '550px',
        margin: '0 auto',
        paddingTop: '0.4em !important',
        '& .MuiTextField-root': {
            backgroundColor: 'inherit'
        },
        '& .MuiOutlinedInput-notchedOutline' : {
            borderColor: '#FFF',
        },
        '& .MuiInputLabel-outlined': {
            color: '#FFF !important',
        },
        '& .MuiOutlinedInput-inputMultiline': {
            color: '#FFF !important',
        },
    }
}));

const CommentButton = withStyles((theme) => ({
    root: {
      color: '#FFF',
      backgroundColor: theme.palette.primary.solidGreen,
      '&:hover': {
        backgroundColor: theme.palette.primary.solidGreen,
      },
    },
}))(Button);

class RatingForm extends Component {

    constructor() {
        super();
 
        this.state = {
            ratingformopen: false,
            stars: [false, false, false, false, false],
            starcount: 0,
            ratingid: 0,
            comment: '',
            processing: false
        }
    }

    componentDidMount() {
        this.previousRating();
    }

    starstruck = (e) => {
        e.preventDefault();
        let j = e.currentTarget.id.replace('i','')*1;
        this.starbase(j);
        return false;
    }

    starbase = (k) => {
        let g = [];
        for(var i = 0; i < 5; i++){
            g[i] = k>=i;
        }
        this.setState({
            stars: g,
            starcount: k
        });
    }

    previousRating = () => {
        ratingService.get(this.props.eventid)
            .then( ( response ) => response.json(), error => {
                this.props.dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
            } )
            .then( json => {
                if ( json && json.data ) {
                    if(json.data[0]){
                        this.setState( {
                            comment: json.data[0].comments,
                            ratingid: json.data[0].id,
                        });
                        this.starbase(json.data[0].rating-1);
                    }
                }
            } );
        
    }

    handleSubmitViaEnter = ( e ) => {
        if ( e.key === 'Enter' ) {
            this.handleSubmit( e );
        }
    }

    handleSubmit = ( e ) => {
        e.preventDefault();
        if(this.state.ratingid > 0){
            this.patchRating();
        }else{
            this.postRating();
        }

    }

    postRating = () => {
        this.setState( {
            processing: true
        });
        ratingService.add( this.state.starcount+1, this.state.comment, this.props.eventid )
            .then( ( response ) => response.json(), error => {
                this.props.dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                this.setState( {
                    processing: false
                });
            } )
            .then( json => {
                if ( json && json.data ) {
                    this.setState( {
                        ratingid: json.data.id,
                        processing: false
                    });
                }
            } );
    }

    patchRating = () => {
        this.setState( {
            processing: true
        });
        ratingService.patch( this.state.starcount+1, this.state.comment, this.state.ratingid )
                    .then( ( response ) => response.json(), error => {
                        this.props.dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                        this.setState( {
                            processing: false
                        });
                    } )
                    .then( json => {
                        if ( json && json.data ) {
                            this.setState( {
                                processing: false
                            });
                        }
                    } );
    }

    render() {

        const style = useStyles();

        const items = [];
        let icon = 'star';
        for (var i=0;i < this.state.stars.length; i++) {
            icon = this.state.stars[i] ? 'star' : 'star_border';
            items.push(<a id={'i'+i} href="#" key={i+1} onclick={this.starstruck}><Icon fontSize={'large'}>{icon}</Icon></a>);
        }
        let desc = (this.state.ratingid > 0) ? "You've already rated this event, you can update it" : "How would you rate this event" ;
        let buttontext = (this.state.ratingid > 0) ? "Update your rating" : "Rate the event";

        return (

                <div class={style.ratingform} id="rate">
                    <h1>Rate this event.</h1>
                    <div class={'buffer '+style.buffer}>
                        <p>{desc}</p>
                        {this.props.loggedIn && this.props.role !== authConst.ROLE_STUDENT && <p>This is only available to students</p>}
                    
                        {this.state.processing && <LinearProgress indeterminate />}
                        {this.props.role === authConst.ROLE_STUDENT && <>
                            <div class={style.stars}>
                                {items}
                            </div>
                            <form onSubmit={this.handleSubmit} onKeyPress={this.handleSubmitViaEnter}>
                                <TextField label={'Add any comments'} rows={4}  value={this.state.comment} onInput={linkState(this, 'comment')} required={false} className={style.commentbox} name="Comment" variant="outlined" multiline fullWidth/>
                                <div class="buttons right">
                                    <CommentButton variant="contained" color="primary" type="submit">{buttontext}</CommentButton>
                                </div>
                            </form>
                        </>}
                    </div>
                </div>
        );
    }
}

function mapStateToProps( state ) {
    const { loggedIn, role } = state.auth; //(see loading in auth.reducer)
    return {
        loggedIn,
        role
    };
}

export default connect( mapStateToProps )( RatingForm );