import { Component } from 'preact';
import { connect } from 'react-redux';
import { Link } from 'preact-router';
import MenuLinks from '../../_helpers/MenuLinks.js';
import AppBarDropDown from '../appbardropdown';
import { makeStyles, Button, Grid } from '@material-ui/core';
import { modalConst } from '../../_constants/modal.constants.js';
import { renderModal } from '../../_actions/modal.actions.js';

const useStyles = makeStyles(theme => ({
    bigenough: {
        display: 'none',
        [theme.breakpoints.up('xl')]:  {
            display: 'inherit'
        }
    },
    middle_section: {
        display: 'inline-flex',
        verticalAlign: 'middle',
        flexGrow: 1,
        '& >ul >li': {
            display: 'inline-block',
            listStyleType: 'none',
            paddingRight: 23,
            [theme.breakpoints.down('md')]: {
                paddingRight: 14,
            },

            '& >a, & div>a': {
                textDecoration: 'none',
                color: theme.palette.secondary.main
            }
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '12px'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '12px'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '13px'
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '14px'
        }
    }
}));

class DesktopMenu extends Component {

    signInClickOpen = () => {
        this.props.dispatch( renderModal( {
            which: modalConst.OPEN_SIGNIN
        } ) );
    }

    render() {

        const style = useStyles();

        const AppBarDropDownItems = [
            {title: 'Discover Workplaces', link: MenuLinks.Workplaces},
            {title: 'Industry Sectors', link: MenuLinks.IndustrySectors},
            {title: 'Employers - Get Involved', link: MenuLinks.EmployersGetInvolved},
            {title: 'Become A Mentor', link: MenuLinks.BecomeAMentor}
        ];

        return (
            <div class={style.middle_section}>
                <ul>
                    <li><Link href={MenuLinks.Events} title="" aria-label="Events">Events</Link></li>
                    <li>     
                        <AppBarDropDown items={AppBarDropDownItems}>
                            Workplaces
                        </AppBarDropDown>
                    </li>
                    <li><Link href={MenuLinks.StayLearning} title="" aria-label="Stay Learning">Stay Learning</Link></li>
                    <li><Link href={MenuLinks.Inspiration} title="" aria-label="Inspiration & Resources">Inspiration<span class={style.bigenough}> &amp; Resources</span></Link></li>
                    <li><Link href={MenuLinks.JobBoard} title="" aria-label="Job Board">Job Board</Link></li>
                </ul>
            </div>
        );
    }
}

function mapStateToProps( state ) {
    const { loggedIn } = state.auth; //(see loading in auth.reducer)
    return {
        loggedIn
    };
}

export default connect( mapStateToProps )( DesktopMenu );