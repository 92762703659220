import { Component } from 'preact';
import { Link } from 'preact-router';
import { connect } from 'react-redux';
import cx from 'classnames';
import MenuLinks from '../../_helpers/MenuLinks.js';
import AddMenu from '../addmenu';
import { makeStyles, Button } from '@material-ui/core';
import { renderModal } from '../../_actions/modal.actions.js';
import { modalConst } from '../../_constants/modal.constants.js';

const useStyles = makeStyles(theme => ({
    
    root: {
        flexGrow: 1
    },
    signin_button: {
        marginTop: '.5em',
        width: '70%',
        maxWidth: '200px',
        backgroundColor: theme.palette.secondary.main,
        marginBottom: 10,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark
        }
    },
    mobile_nav_inner: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        backgroundColor: theme.palette.primary.main,
        '& .menu_items': {
            margin: 0,
            padding: '0 0 1.5em 0',
            textAlign: 'center',
            width: '100vw',
            marginLeft: 'calc(-1 * ((100vw - 100%) / 2))'
  
        },

        '& li': {
            padding: '8px 0',
            listStyleType: 'none',
            position: 'relative',

            '& .workplace_wrapper': {
                backgroundColor: theme.palette.primary.light,
                borderRadius: 5,
                paddingLeft: 0,
                marginTop: '1em',

                '& a': {
                    fontSize: '1em',
                    textTransform: 'initial'
                },

                '& li': {
                    padding: '10px 0'
                }
            }
        },
        
        '& a:not(.signin_button_clear)': {
            textDecoration: 'none',
            color: theme.palette.primary.solidGreen,
            textTransform: 'uppercase',
            fontSize: '1.2em',
            cursor: 'pointer'
        }
    },
    expand_workplaces: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '2em',
        fontFamily: 'serif',
        lineHeight: '0.6',
        display: 'block',
        textAlign: 'right',
        margin: '0',
        position: 'absolute',
        right: '1.2em',
        top: '0.2em',
        color: theme.palette.secondary.main
    },
    add_to_dashboard: {
        color: theme.palette.greenblue,
        backgroundColor: theme.palette.primary.light,
        borderRadius: 5,
        marginTop: 10,
        '& li': {
            marginLeft: '0 !important'
        }
    },
    add_menu: {
        padding: '0'
    }
}));

class MobileMenu extends Component {

    constructor() {
        super();
 
        this.state = {
            workplacemenuopen: false,
            addmenuopen: false,
        }
    }

    openAddMenu = () => {
        this.setState( state => ( { addmenuopen: !state.addmenuopen } ) );
    }

    

    openWorkplaceMenu = () => {
        this.setState( state => ( { workplacemenuopen: !state.workplacemenuopen } ) );
    }

    signInClickOpen = () => {
        this.props.toggle();
        this.props.dispatch( renderModal( {
            which: modalConst.OPEN_SIGNIN
        } ) );
    }

    render() {
        
        var plus_class = cx( 'sprite', {
            'plus': !this.state.addmenuopen,
            'minus': this.state.addmenuopen
        } );
        var workplace_class = cx( 'workplacelink', {
            'open': this.state.workplacemenuopen
        } );

        const style = useStyles();
        let beta = true;

        return (
            <div class={style.mobile_nav_inner}>
                
                {this.props.open && <nav><ul class="menu_items">
                    {this.props.loggedIn && <li class={style.add_to_dashboard}>
                        <a href="#" title="Add" aria-label="Add" onClick={this.openAddMenu}><i class={plus_class} aria-hidden="true"></i></a>
                        {this.state.addmenuopen && <ul class={style.add_menu}>
                            <AddMenu close={this.openAddMenu}/>
                        </ul>}
                    </li>
                    }
                    <li>
                        <Link href={MenuLinks.Home} onClick={this.props.toggle} title="" aria-label="Home">My next move</Link>
                    </li>
                    <li>
                        <Link href={MenuLinks.Events} onClick={this.props.toggle} title="" aria-label="Events">Events</Link>
                    </li>
                    <li class={workplace_class}>
                        <a title="" aria-label="Expand Workplaces Menu" onClick={this.openWorkplaceMenu}>
                            Workplaces <i class={style.expand_workplaces + ' plusminus'} aria-hidden="true"></i>
                        </a>
                        {this.state.workplacemenuopen && <ul class="workplace_wrapper">
                            <li><a href={MenuLinks.Workplaces} onClick={this.props.toggle} title="" aria-label="Discover workplaces">Discover workplaces</a></li>
                            <li><a href={MenuLinks.IndustrySectors} onClick={this.props.toggle} title="" aria-label="Industry Sectors">Industry Sectors</a></li>
                            {false && <li><a href={MenuLinks.EmployersGetInvolved} onClick={this.props.toggle} title="" aria-label="Employers - Get involved">Employers - Get involved</a></li>}
                            {<li><a href={MenuLinks.BecomeAMentor} onClick={this.props.toggle} title="" aria-label="Become a mentor">Become a mentor</a></li>}
                        </ul>
                        }
                    </li>
                    <li>
                        <Link href={MenuLinks.StayLearning} onClick={this.props.toggle} title="" aria-label="Stay Learning">Stay Learning</Link>
                    </li>
                    <li>
                        <Link href={MenuLinks.Inspiration} onClick={this.props.toggle} title="" aria-label="Inspiration &amp; Resources">Inspiration &amp; Resources</Link>
                    </li>
                    <li>
                        <Link href={MenuLinks.JobBoard} onClick={this.props.toggle} title="" aria-label="Job Board">Job Board</Link>
                    </li>
                    {false && !this.props.loggedIn && <li>
                        <Button secondary={true} onClick={this.signInClickOpen} classes={{root:style.signin_button}}>Sign In</Button>
                    </li>
                    }
                    {true && !this.props.loggedIn &&
                        <Button  onClick={this.props.toggle} href={MenuLinks.Launch} classes={{root: 'signin_button' + ' ' + 'signin_button_clear'}}>Create Account</Button>
                    }

                    { false && <img src="/assets/images/maintenance.png" alt="logo" class={'logo_title'} style={{height: '46px'}}  title="Site undergoing maintenance"/>}

                    {this.props.loggedIn && <li class={style.dashboardmobile}><a href={MenuLinks.Dashboard} onClick={this.props.toggle} title="" aria-label="Dashboard">Dashboard</a></li>}
                    {this.props.loggedIn && <li><a href={MenuLinks.Account} onClick={this.props.toggle} title="" aria-label="Account">Account</a></li>}
                    

                    {this.props.loggedIn && <li>
                        <Button color="secondary" variant="contained" classes={{root:style.signin_button + ' ' + style.signin_button_dropdown}} onClick={e => {
                            e.preventDefault();
                            this.props.getOut();
                            this.props.toggle();
                        }}>Sign Out</Button>
                    </li>}
                </ul></nav>}
                
            </div>
        );
    }
}

function mapStateToProps( state ) {
    const { loggedIn } = state.auth; //(see loading in auth.reducer)
    return {
        loggedIn
    };
}

export default connect( mapStateToProps )( MobileMenu );