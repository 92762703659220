export const modalConst = {
    RENDER: 'MODAL_RENDER',
    REMOVE: 'MODAL_REMOVE',
    OPEN_LEARNING: 'ADD_LEARNING_PROMPT',
    OPEN_WORKPLACE: 'ADD_WORKPLACE_PROMPT',
    OPEN_EVENT: 'ADD_EVENT_PROMPT',
    OPEN_INSPIRATION: 'ADD_INSPIRATION_PROMPT',
    OPEN_SIGNIN: 'SIGNIN_PROMPT',
    OPEN_STUDENT: 'ADD_STUDENT',
    OPEN_MENTOR: 'ADD_MENTOR',
    OPEN_CAREER: 'ADD_CAREER',
    OPEN_AREA: 'ADD_AREA',
    OPEN_ACTION: 'ADD_ACTION'
};
