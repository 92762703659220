import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { debounce } from "throttle-debounce";
import { Button, Grid } from '@material-ui/core';
import Listing from './../listing';
import { memoActions } from '../../_actions/memo.actions';
import { starActions } from '../../_actions/star.actions';
import { fetchConst } from '../../_constants/fetch.constants';
import { panelActions } from '../../_actions/panel.actions';


class Results extends Component {

    constructor(props) {
        super(props);
        this.state = {
            initialized: ( typeof this.props.observable != 'undefined' ) ? this.props.observable : true,
            loadingdata: true,
            items: [],
            error: null,
            starsLoaded: false,
            searchterm: '',
            sectors: [],
            subs: [],
            sort: '',
            loadmore: false, 
            loadingmore: false,
            offset: 0
        };
        this.searchDebounced = debounce(500, this.theSearch);
    }

    theSearch( type, params, t ) {

        panelActions.getPanels( type, params, false )
            .then( ( response ) => response.json(), error => {
                t.props.dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
            } )
            .then( ( json ) => {
                if ( t.props.loggedIn ) {
                    t.props.dispatch( starActions.starsUponThars( t.props.type, json.data ) )
                        .then( output => {
                            t.setResults( output, json.meta );
                            t.setState( { starsLoaded: true } );
                        } );
                } else {
                    t.setResults( json.data, json.meta );
                }
            } );
    }

    componentDidMount() {
        this.setState( {
            searchterm: this.props.searchterm,
            sectors: this.props.sectors,
            subs: this.props.subs,
            sort: this.props.sort
        }, () => this.performSearch( true ) );
        
    }


    componentWillReceiveProps( nextProps ) {
        if ( nextProps.observable ) {
            this.setState( { initialized: nextProps.observable } );
        }
        if ( this.state.searchterm != nextProps.searchterm || this.state.sectors != nextProps.sectors  || this.state.subs != nextProps.subs ) {
            this.setState( {
                searchterm: nextProps.searchterm,
                sectors: nextProps.sectors,
                subs: nextProps.subs,
                sort: nextProps.sort,
                offset: 0
            }, () => {
                this.performSearch(true);
            } );
            
        }
    }

    prepareParams = () => {
        var params = Object.assign( {}, this.props.params );
        if ( !params.limit ) {
            params.limit = 4;
        }
        if ( this.state.searchterm && this.state.searchterm.length > 0 ){
            params['filter'] = '{"_or": [{"title": {"_icontains": "'+this.state.searchterm+'"}},{"sub_title": {"_icontains": "'+this.state.searchterm+'"}}]}'
            
        } 
        let filters = [];
        if ( this.state.subs && this.state.subs.length > 0 ){
            filters.push('{"industry_sub":{"_in":['+this.state.subs.join( "," )+']}}');
        }else{
            if ( this.state.sectors && this.state.sectors.length > 0 ){
                filters.push('{"industry":{"industry_id":{"_in":['+this.state.sectors.join( "," )+']}}}');
            } 
        }
        //filter={"_and":[{"industry":{"_in":[3]}}]}
        //filter={"industry":{"industry_id":{"_in":[2,3]}}}

        if(filters.length > 0){
            params[ 'filter' ] = '{"_and":[' + filters.join( "," ) + ']}';
        }
        if ( this.state.offset > 0 ) params[ 'offset' ] = this.state.offset;
        if ( this.state.sort && this.state.sort.length > 0 )  params['sort'] = this.state.sort;
        return params;
    }

    loadMoreAppears = ( meta ) => {
        if(meta){
            this.setState( {
                loadmore: ( this.props.loadmore && this.props.params.limit == meta.filter_count )
            } );
        }
    }

    loadmore = ( e ) => {
        e.preventDefault();
        var count = this.state.items.length;
        this.setState( {
            loadingmore: true,
            loadingdata: true,
            offset: count
        }, () => {
            this.performSearch(false);
        });
        return false;
    }

    setResults = ( output, meta ) => {
        //Strips out the loading items
        let collect = this.state.loadingmore ? [ ...this.state.items.filter( a => a.id ), ...output ] : output;
        this.setState( { items: collect, loadingmore: false, loadingdata: false }, () => this.loadMoreAppears( meta ) );
    }

    performSearch = ( firsttime ) => {
        let collect = firsttime ? [ {}, {}, {}, {} ] : [ ...this.state.items, ...[ {}, {}, {}, {} ] ];
        this.setState( { items: collect } );
        let pick = this.props.debounced && !firsttime ? this.searchDebounced : this.theSearch;
        let k = this.prepareParams();
        
        pick( this.props.type, k, this );
            
    }

    render() {
        return (
            <>
                <Grid container spacing={3}>
                    {this.state.initialized && this.state.items && this.state.items.map(item => 
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <Listing listing={item} type={this.props.type} loading={this.state.loadingdata && !this.state.loadingmore}/>
                        </Grid>
                    )}
                </Grid>
                {this.state.loadmore && !this.state.loadingdata && <Button color="secondary" variant="contained" href={'#'} onClick={this.loadmore}>Load More</Button>}
            </>
        );
    }
}

function mapStateToProps( state ) {
    const { loggedIn } = state.auth;
    return {
        loggedIn
    };
}

export default connect( mapStateToProps )( Results );