import { h, Component } from 'preact';
import { Button } from '@material-ui/core';
import JobFilter from '../jobfilter';
import Results from '../results';

export default class ListingPreview extends Component {


    constructor( props ) {
        super( props );

        this.state = {
            sort: this.props.sort
        };   
    }

    changeSort = ( event ) => {
        this.setState({
            sort: event.target.value
        })
    }

    render() {
        

        let debounced = false,
            loadmore = false,
            sectors = [],
            subs = [];
        if ( this.props.loadmore ) {
            debounced = true;
            loadmore = true;
        }
        if ( this.props.sector ) {
            sectors.push( this.props.sector.id );
        }
        if ( this.props.sub ) {
            subs.push( this.props.sub.id );
        }

        const qp = (this.props.options.params) ? this.props.options.params : { 'limit': 4 };
        if(!qp['limit']) qp['limit'] = 4;
                
        if ( this.props.random ){
            //TODO
            //qp['sort'] = '?';
        }

        var o = this.props.options;

        let elevate = (this.props.elevate)? ' elevate_form': ''

        return (
            <div class={'padout'+elevate}>
                {this.props.jobfilter && <JobFilter changesort={this.changeSort} /> }
                {o.title && <h2>{o.title} <sub class="centered">{o.sub}</sub></h2>}
                <Results type={o.type} debounced={debounced} loadmore={loadmore} params={qp} sectors={sectors} subs={subs} sort={this.state.sort} />
                {!this.props.loadmore && <Button color="secondary" variant="contained" href={o.link}>{o.button}</Button>}
            </div>
        );
    }
}