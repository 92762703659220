import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { route, Link } from 'preact-router'
import { makeStyles, AppBar, Button, Grid, IconButton, Icon, Toolbar } from '@material-ui/core';
import MenuLinks from '../../_helpers/MenuLinks.js';
import DesktopMenu from '../desktopmenu';
import MobileMenu from '../mobilemenu';
import HeaderUser from '../headeruser';
import { authActions } from '../../_actions/auth.actions';
import { renderModal } from '../../_actions/modal.actions';

const useStyles = makeStyles(theme => ({
  appbar_z:  {
    padding: '15px 0 0 0',
    height: '90px',
    textTransform: 'uppercase',
    '& a' : {
        textDecoration: 'none',
        cursor: 'pointer'
    },
    ['@media print']: {
      display: 'none'
    },
    fontFamily: "'Gotham Rounded A', 'Gotham Rounded B'"
  },
  root_nav: {
    flexGrow: 1,
    position: 'relative'
  },
  right_section: {
    right: 0,
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
  }
}));

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mobilemenuopened: false
        }
    }

    handleRedirect = (url) => {
        route( url, true );
    }

    toggleMenu = () => {
        this.setState( state => ( { mobilemenuopened: !state.mobilemenuopened } ) );
    }

    signInClickOpen = () => {
        this.props.dispatch( renderModal( {
            which: 'SIGNIN_PROMPT'
        } ) );
    }

    getOut = () => {
        this.props.dispatch( authActions.signOut() );
    }
    
    render() {
        var style = useStyles();

        return (
            <AppBar classes={{root: style.appbar_z}} position="sticky">
                <Toolbar elevation={0}>
                    <Grid container classes={{container : style.root_nav}} spacing={0} alignItems="center">
                        <Grid item xs={10} md={9}>
                            <div class={'left_section' + ' title'}><Link href={MenuLinks.Home} title="" aria-label="Home">
                                <img src="/assets/images/MyNextMove-Logo.png"  srcset="/assets/images/MyNextMove-Logo@2x.png 2x,/assets/images/MyNextMove-Logo.png 1x" alt="logo" class={'logo_title'} title="My Next Move"/>
                            </Link></div>
                            <DesktopMenu />
                        </Grid>
                        <Grid item classes={{item : 'mobile_nav_inner'}} xs={2}>
                            <IconButton edge="end" color="secondary" style={{float: 'right'}} aria-label="menu" onClick={this.toggleMenu}>
                                <Icon fontSize="large" >menu</Icon>
                            </IconButton>                            
                        </Grid>
                        { true && <Grid item classes={{item : style.right_section}} md={3}>
                            {!this.props.auth.loggedIn &&
                                <Button href={MenuLinks.Launch} classes={{root: 'signin_button' + ' ' + 'signin_button_clear'}}>Create<br />Account</Button>
                            }
                            {!this.props.auth.loggedIn &&
                                <Button color="secondary" variant="contained" onClick={this.signInClickOpen} classes={{root: 'signin_button'}}>Sign In</Button>
                            }
                            {this.props.auth.loggedIn && <HeaderUser getOut={this.getOut} />}
                        </Grid>}
                        { false && <Grid item classes={{item : style.right_section}} md={3}>
                            <img src="/assets/images/maintenance.png" alt="logo" class={'logo_title'} style={{height: '46px'}} title="Site undergoing maintenance"/>
                        </Grid>}
                    </Grid>
                </Toolbar>
                <MobileMenu menustate={true} open={this.state.mobilemenuopened} getOut={this.getOut} toggle={this.toggleMenu} /> 
            </AppBar>
        )
    }
}

function mapStateToProps( state ) {
    const { auth } = state; //(see loading in auth.reducer)
    return {
        auth
    };
}

export default connect( mapStateToProps )( Header )

