import { useState } from 'preact/hooks';
import {  Select, FilledInput } from '@material-ui/core';
import style from './style.scss';

//export default class HeaderDropDown extends Component {
export default function JobFilter() {

   


    return (
        <div class={style.jobfilter}>
            <span>Sort by </span>
            <Select 
                native 
                value={this.state.school} 
                onChange={this.props.changesort}
                input={<FilledInput name="joborder" id="joborder" style="background-color:#FFF;" />}
            >
                <option value={'-modified_on'}>Last Updated</option>
                <option value={'title'}>Alphabetical</option>
            </Select>
        </div>
    );

}

                    