import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import SoundLikeYou from '../../../components/soundlikeyou';
import AddYourOwn from '../../../components/addyourown';
import ListingPreview from '../../../components/listingpreview';
import Quotes from './Quotes';
import MenuLinks from '../../../_helpers/MenuLinks';
import { DirectusImage } from '../../../components/image';
import { listConst } from '../../../_constants/lists.constants';
import style from '../style.scss';

class SingleLayout extends Component {

    render() {
        var bodytext = DOMPurify.sanitize(marked.parse( this.props.sector.single_layout_textblock ));

        let options = {
            type: listConst.INSPIRATION,
            link: MenuLinks.Inspiration,
            title: this.props.sector.title + ' Resources',
            sub: "See what's coming up, get involved and see what's out there."
        }

        let workplace_options = {
            type: listConst.WORKPLACE,
            link: MenuLinks.Workplaces,
            title: 'Workplaces in ' + this.props.sector.title,
            sub: 'Discover workplaces and businesses that you might be interested in.'
        }

        let events_options = {
            type: listConst.EVENT,
            link: MenuLinks.Events,
            title: 'Upcoming Events',
            sub: "See what's coming up, get involved and see what is out there.",
            button: 'View all events'
        }

        return (
            <div>
                <div class={style.single_wrap}>
                    <Grid container spacing={2} className={'pad '+style.sub_preview}>
                        <Grid item xs={12} sm={12}>
                            <h2>{this.props.sector.title}</h2>
                        </Grid>
                        <Grid item xs={12} sm={6} dangerouslySetInnerHTML={{__html: bodytext}}>

                        </Grid>
                        <Grid item xs={12} sm={6} className={style.media}>
                            {this.props.sector.single_layout_video_link && <div class={'videocontainer'}><iframe title="Inspiration" width="100%" height="100%" src={this.props.sector.single_layout_video_link} frameborder="0" allowfullscreen></iframe></div>}
                            <DirectusImage image={this.props.sector.single_layout_image_1} size={768} classy={style.subimage} />
                            <DirectusImage image={this.props.sector.single_layout_image_2} size={768} classy={style.subimage} />
                            <DirectusImage image={this.props.sector.single_layout_image_3} size={768} classy={style.subimage} />
                        </Grid>
                    </Grid>
                </div>

                <Quotes sector={this.props.sector} />

                <SoundLikeYou />

                <ListingPreview options={options} sector={this.props.sector} loadmore={true} />

                <ListingPreview options={workplace_options} sector={this.props.sector} loadmore={true}/>
                
                <ListingPreview options={events_options} />
                
                <AddYourOwn flip={true}  type={listConst.WORKPLACE} />
            </div>
        );
    }
}

export default connect()( SingleLayout );
