import './style/index.scss';
import { ThemeProvider, StylesProvider } from '@material-ui/styles';
import App from './components/App';
import theme from './theme';
/*
const jss = create({
    ...jssPreset(),
    // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
    insertionPoint: 'jss-insertion-point',
});*/


export default () => (
    
    <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
            <App />
        </StylesProvider>
    </ThemeProvider>
);
