import { areasConst } from '../_constants/areas.constants';

export function areas( state = [], action ) {
    switch ( action.type ) {
        case areasConst.LOADED:
            return action.areas;
        case areasConst.ADD:
            let newArray = state.slice();
            newArray.push( action.area )
            return newArray
        case areasConst.PATCH:
            let n = state.slice();
            let index = n.findIndex(({ id }) => id === action.area.id);
            n[index] = action.area;
            return n;
        case areasConst.REMOVE:
            let tempArray = state.filter( obj => {
                return obj.id != action.id;
            } );
            return tempArray
        case areasConst.UNLOADED:
            return [];
        default:
            return state;
    }
}
