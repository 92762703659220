import { fetchOptions } from '../_helpers/request.helpers';

export const quotesService = {
    get
};


function get(sector_id, sub_id) {
    const reqOptions = Object.assign( {}, fetchOptions(true), {
        method: 'GET'
    } );
    let filter = ( sub_id ) ? '[industry_sub][_eq]=' + sub_id : '[industry][_eq]='+sector_id
    return fetch( process.env.PREACT_APP_API_URL + '/items/quotes?filter'+filter+'&fields=*,image.*,business.title,industry.title,industry_sub.title', reqOptions );
}
