import { actionsConst } from '../_constants/actions.constants';
import { fetchConst } from '../_constants/fetch.constants';
import { actionsService } from '../_services/actions.service';
import { memoActions } from './memo.actions';
import { removeModal } from './modal.actions';

export const actionsActions = {
    load,
    add,
    patch,
    remove
};

function load( area_id ) {
    return dispatch => {
        return actionsService.get( area_id )
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( json => {
                if ( json && json.data ) {
                    dispatch( success( json.data ) );
                } else {
                    dispatch( failure( json ) );
                }
            } );
    }

    function success( actions ) {
        if ( !actions ) actions = [];
        return { type: actionsConst.LOADED, actions };
    }

    function failure( error ) {
        return { type: actionsConst.UNLOADED, error };
    }
}


function add( actionPost ) {
    return dispatch => {
        return actionsService.add( actionPost )
            .then( ( response ) => response, error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( () => {
                dispatch( success( actionPost ) );
                dispatch( removeModal() );
                dispatch( memoActions.clear() );
            } );
    }

    function success( action ) {
        return { type: actionsConst.ADD, action };
    }

    //function failure( error ) {
    //    return { type: schoolsConst.ADD_FAILED, error };
    //}
}

function patch( actionPost, action_id ) {
    return dispatch => {
        return actionsService.patch( actionPost, action_id )
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( json => {
                if ( json && json.data ) {
                    dispatch( success( json.data ) );
                    dispatch( removeModal() );
                    dispatch( memoActions.clear() );
                } else {
                    //dispatch( failure( json ) );
                }
            } );
    }

    function success( action ) {
        return { type: actionsConst.PATCH, action };
    }

    //function failure( error ) {
    //    return { type: schoolsConst.ADD_FAILED, error };
    //}
}

function remove( id ) {
    return dispatch => {
        return actionsService.remove( id )
            .then( ( response ) => response, error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( response => {
                if ( response.status == 204 ) {
                    dispatch( success( id ) );
                    //Route( MenuLinks.Dashboard + '/' + dashboard.SCHOOLS );
                } else {
                    dispatch( failure( text ) );
                }
            } );
    }

    function success( id ) {
        return { type: actionsConst.REMOVE, id };
    }

    function failure( error ) {
        //return { type: schoolsConst.REMOVE_FAILED, error };
    }
}