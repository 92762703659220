import { useState } from 'preact/hooks';
import { Link } from 'preact-router';
import { makeStyles, MenuItem, Menu } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    dropdown: {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.secondary.main,
            borderWidth: 1,
            borderStyle: 'solid',
            marginTop: 10
    },
    dropdown_item: {
        display: 'block',
        padding: '10px 20px',
        cursor: 'pointer',
        fontSize: '1em',
        color: 'white',
        minHeight: '38px',
        textTransform: 'uppercase',
        fontSize: '0.75em',

        '& >a': {
            color: 'white',
            textDecoration: 'none'
        },

        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        }
    }
}));


export default function AppBarDropDown() {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = event => {
        setAnchorEl(null);
    };
    
    const id = 'wp-popover';

    const style = useStyles();

    return (
        <>
            <a aria-describedby={id} aria-haspopup="true" aria-label={this.props.title} title={this.props.title} class={this.props.aclass} onClick={handleClick}>
                {this.props.children}
            </a>

            <Menu id={id} anchorEl={anchorEl} getContentAnchorEl={null} transformOrigin={{ vertical: 'top', horizontal: 'center'}} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} keepMounted open={Boolean(anchorEl)} onClose={handleClose} classes={{paper:style.dropdown}}>
                {this.props.items.map( item => {
                    if(item.onClick){
                        return (
                            <MenuItem classes={{root:style.dropdown_item}} onClick={item.onClick}>
                                {item.title}
                            </MenuItem>);
                    }else{
                        return (
                            <MenuItem classes={{root:style.dropdown_item}} onClick={handleClose}>
                                <Link href={item.link} title="" aria-label={item.title}>{item.title}</Link>
                            </MenuItem>);
                    }
                })
                }
            </Menu>
        </>
    );
    
}

                    