export const listConst = {
    EVENT: 'event',
    INSPIRATION: 'inspiration',
    WORKPLACE: 'workplace',
    LEARNING: 'learning',
    JOBS: 'jobs',
    CUSTOM: 'custom'
};

export const dashboard = {
    SCHOOLS: 'schools',
    MENTORS: 'mentors',
    CAREERS: 'careers',
    STUDENTS: 'students',
    EVENTS: 'events'
};
