import { useState } from 'preact/hooks';
import { Menu, MenuItem, Icon } from '@material-ui/core';
import style from './style.scss';

//export default class HeaderDropDown extends Component {
export default function ContextMenu() {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (x) => {
        if(typeof x === "function"){
            x();
        }   
        setAnchorEl(null);
    };
    
    const id = 'cm-popover'


    return (
        <>
            <a aria-describedby={id} aria-haspopup="true" class={style.icon_link + ' editanchor'} title="" onClick={handleClick} aria-label="Edit"><Icon>edit</Icon></a>
            <Menu id={id} anchorEl={anchorEl} getContentAnchorEl={null} transformOrigin={{ vertical: 'top', horizontal: 'center'}} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => handleClose(this.props.editCustomLink)}>Edit</MenuItem>
                <MenuItem onClick={() => handleClose(() => this.props.deleteCustomLink(this.props.listing))}>Delete</MenuItem>
            </Menu>
        </>
    );

}

                    