import { h, Component } from 'preact';
import style from './style.scss';

export default class SoundLikeYou extends Component {

    constructor( props ) {
        super( props );
    }

    render() {
        return (
            <div class={'green_section'}>
                <div class={'fullwidthbg'}>
                    <div class={'padout '+ style.soundpad}>
                        <h3>Does this sound like you?</h3>
                        <p>If this industry sector interests and excites you please get in touch with your career advisor or mentor today.</p>
                    </div>
                    <div class={style.illustration}></div>
                </div>
            </div>
        );
    }
}
