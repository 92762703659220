import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { handleJsonResponse, handleNetworkError } from '../../../_helpers/request.helpers';
import { quotesService } from '../../../_services/quotes.service';
import style from '../style';

class Quotes extends Component {

    state = {
        initialized: false,
        quotes: []
    };

    componentDidMount() {
        let sectorid = ( this.props.sector ) ? this.props.sector.id : 0;
        let sub_id = ( this.props.sub ) ? this.props.sub.id : null;
        quotesService.get( sectorid, sub_id )
            .then( 
                response => handleJsonResponse( response, this.props.dispatch ), 
                error => handleNetworkError( error, this.props.dispatch )
            )
            .then( ( json ) => {
                this.setState( {
                    initialized: true,
                    quotes: json.data
                } );
            } );
    }

    render() {
        return (
            <>
                {!this.state.initialized && <div class="padout"><div class={'rocket'}></div></div>}
                {this.state.quotes.map(quote => 
                    <div class="padout">
                        <div class={style.quote}>
                            <Grid container spacing={4} >
                                <Grid item xs={12} sm={4}>
                                    {quote.image && <img src={process.env.PREACT_APP_API_URL+'/assets/'+quote.image.id+'?fit=cover&width=768&height=900&quality=80'} class={style.subimage} />}

                                </Grid>
                                <Grid item xs={12} sm={8} style={{textAlign: 'left'}}>
                                    <h4>{quote.name}</h4>
                                    <b>From {quote.business.title} - { ( this.state.sub ) ? quote.industry_sub.title : quote.industry.title}</b>
                                    <p>{quote.description}</p>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default connect()( Quotes );