import { schoolsConst } from '../_constants/schools.constants';
import { fetchConst } from '../_constants/fetch.constants';
import { schoolService } from '../_services/school.service';
import MenuLinks from '../_helpers/MenuLinks';
import { dashboard } from '../_constants/lists.constants';
import { memoActions } from './memo.actions';
import { removeModal } from './modal.actions';

export const schoolsActions = {
    load,
    add,
    remove
};

function load() {
    return dispatch => {
        schoolService.get()
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( json => {
                if ( json && json.data ) {
                    dispatch( success( json.data ) );
                } else {
                    dispatch( failure( json ) );
                }
            } );
    }

    function success( schools ) {
        return { type: schoolsConst.LOADED, schools };
    }

    function failure( error ) {
        return { type: schoolsConst.UNLOADED, error };
    }
}

function add( name ) {
    return dispatch => {
        return schoolService.add( name )
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( json => {
                if ( json && json.data ) {
                    dispatch( success( json.data ) );
                    dispatch( removeModal() );
                    dispatch( memoActions.clear() );
                } else {
                    dispatch( failure( json ) );
                }
            } );
    }

    function success( school ) {
        return { type: schoolsConst.ADD_SCHOOL, school };
    }

    function failure( error ) {
        return { type: schoolsConst.ADD_FAILED, error };
    }
}


function remove( id, redirect ) {
    return dispatch => {
        return schoolService.remove( id )
            .then( ( response ) => response.text(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( text => {
                if ( !text ) {
                    dispatch( success( id ) );
                    redirect( MenuLinks.Dashboard + '/' + dashboard.SCHOOLS );
                } else {
                    dispatch( failure( text ) );
                }
            } );
    }

    function success( id ) {
        return { type: schoolsConst.REMOVE_SCHOOL, id };
    }

    function failure( error ) {
        //return { type: schoolsConst.REMOVE_FAILED, error };
    }
}