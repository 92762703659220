import { studentsConst } from '../_constants/students.constants';

const initialState = [];

export function students( state = initialState, action ) {
    switch ( action.type ) {
        case studentsConst.LOADED:
            return action.students;
        case studentsConst.UNLOADED:
            return initialState;
        default:
            return state;
    }
}