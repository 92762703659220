import { notificationsConst } from '../_constants/notifications.constants';

const initialState = {
    open: false
};

export function notifications( state = initialState, action ) {
    switch ( action.type ) {
        case notificationsConst.OPEN:
            return {
                open: true
            };
        case notificationsConst.CLOSE:
            return initialState;
        default:
            return state;
    }
}

