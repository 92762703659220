import { profileConst } from '../_constants/profile.constants';

const initialState = {
    id: 0
};

export function profile( state = initialState, action ) {
    switch ( action.type ) {
        case profileConst.SUCCESS:
            if ( action.profile.school_year ) {
                return {
                    id: action.profile.id,
                    first_name: action.profile.first_name,
                    last_name: action.profile.last_name,
                    avatar: action.profile.avatar
                };
            } else { //staff
                if ( action.profile.school ) {
                    return {
                        id: action.profile.id,
                        school: action.profile.school.id,
                        first_name: action.profile.first_name,
                        last_name: action.profile.last_name,
                        avatar: action.profile.avatar
                    };
                } else if ( action.profile.first_name ) {
                    return {
                        id: action.profile.id,
                        first_name: action.profile.first_name,
                        last_name: action.profile.last_name,
                        avatar: action.profile.avatar
                    };
                } else {
                    return {
                        id: action.profile.id
                    };
                }
            }
        case profileConst.FAILURE:
            return state;
        case profileConst.CLOSE:
            return initialState;
        default:
            return state;
    }
}

