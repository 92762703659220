import { Component } from 'preact';
import { connect } from 'react-redux';
import { route, Link } from 'preact-router';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, LinearProgress, makeStyles } from '@material-ui/core';
import Message from '../../message';
import linkState from 'linkstate';
import { memoActions } from '../../../_actions/memo.actions';
import { removeModal } from '../../../_actions/modal.actions';
import { authActions } from '../../../_actions/auth.actions';
import MenuLinks from '../../../_helpers/MenuLinks';

const useStyles = makeStyles(theme => ({
    forgotten_link: {
        redirect: null,
        margin: '-0.7em 0 1.3em 0',
        textAlign: 'left',
        display: 'block',
        width: '100%',
        fontSize: '.8em'
    }
}));


class SignIn extends Component {


    constructor( props ) {
        super( props );

        this.state = {
            signInEmail: '',
            signInPassword: ''
        };
    }

    componentDidMount() {
        this.props.dispatch( memoActions.clear() );
    }

    handleSubmitViaEnter = ( e ) => {
        if ( e.key === 'Enter' ) {
            this.handleSubmit( e );
        }
    }
      
    handleLoginRedirect = () => {
        if(this.props.modal_props && this.props.modal_props.noredirect){
            if(this.props.modal_props.callback) this.props.modal_props.callback();
        }else{
            route( MenuLinks.Dashboard, true );
        }
    }

    handleSubmit = ( e ) => {
        e.preventDefault();
        const { signInEmail, signInPassword } = this.state;

        if ( signInEmail && signInPassword ) {
            const { dispatch } = this.props;
            dispatch( authActions.signIn( signInEmail, signInPassword, this.handleLoginRedirect ) );
        } else {
             this.setState( { 'message': 'Please supply an email and password', 'error': true } );
        }
    }

    closeDialogue = ( e ) => {
        this.props.dispatch( memoActions.clear() );
        setTimeout( () => {
            this.props.dispatch( removeModal() )
        }, 500 )
    }
    
    render( {}, { signInEmail, signInPassword } ) {

        const style = useStyles();
        
        return (      
            <Dialog open={this.props.open} onCancel={this.closeDialogue} fullWidth maxWidth="md">
                <form onSubmit={this.handleSubmit} onKeyPress={this.handleSubmitViaEnter} autocomplete="off">
                    <DialogTitle>Login</DialogTitle>
                    {this.props.loggingIn && <LinearProgress indeterminate />}
                    <DialogContent>
                        {this.state.message && !this.props.memo.message &&
                            <Message message={this.state.message} error={this.state.error} />
                        }
                        {this.props.memo.message &&
                            <Message message={this.props.memo.message} error={this.props.memo.error} />
                        }
                        
                        <TextField value={signInEmail} onInput={linkState(this, 'signInEmail')} required={true} name="signInEmail" label="Email" dense={true} autoComplete="username" fullWidth className={style.dialog_input} />
                        <TextField value={signInPassword} onInput={linkState(this, 'signInPassword')} required={true} name="signInPassword" type="password" label="Password" dense={true} autoComplete="current-password" fullWidth className={style.dialog_input} />

                        <Link href={MenuLinks.Forgot} onClick={this.closeDialogue} className={style.forgotten_link} title="" aria-label="Home">Forgotten password</Link>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialogue}>Cancel</Button>
                        <Button variant="text" color="primary" type="submit">Login</Button>
                    </DialogActions>
                </form>
            </Dialog> 
        );
    }
}

function mapStateToProps( state ) {
    const { loggingIn } = state.auth; //(see loading in auth.reducer)
    const { memo, modal } = state;
    return {
        loggingIn,
        modal,
        memo
    };
}

//Not sure why withRouter works here, but setState in callback did not 
export default connect( mapStateToProps )(  SignIn );