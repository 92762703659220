import { fetchOptions } from '../_helpers/request.helpers';
import { personConst } from '../_constants/person.constants';

export const profileService = {
    fetchMyStudentProfile,
    fetchMyStaffProfile,
    fetchProfile,
    fetchStudents,
    fetchCareers,
    createProfile,
    deleteProfile,
    updateProfile,
    newStudent,
    newStudentAdmin,
    newStaffAdmin,
    patchUser,
    deleteUser,
    fetchlatestStudentProfileWithEmail //testing purposes
};

function fetchMyStaffProfile( token, type ) {
    let reqOptions = Object.assign( {}, fetchOptions( false, token ), {
        method: 'GET'
    } );
    var fields = 'id,first_name,last_name,avatar.*';
    if ( type === personConst.CAREER ){
        fields += ',school.*';
    } 
    return fetch( process.env.PREACT_APP_API_URL + '/items/' + type + '?fields=' + fields + '&sort=id', reqOptions );
}

function fetchMyStudentProfile( fields, token ) {
    let reqOptions = Object.assign( {}, fetchOptions( false, token ), {
        method: 'GET'
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/' + personConst.STUDENT + '?fields=' + fields + '&sort=id', reqOptions );
}

function fetchProfile( type, id ) {
    let reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'GET'
    } );
    let fields = '*.*';
    if ( type == personConst.STUDENT ) fields = '*,avatar.*,status.id,status.title,school.name,school.id,mentor.id,mentor.first_name,mentor.last_name,school_contact.id,school_contact.first_name,school_contact.last_name,user_created.id,user_created.email';
    return fetch( process.env.PREACT_APP_API_URL + '/items/' + type + '/' + id + '?fields=' + fields + '&', reqOptions );
}

function fetchlatestStudentProfileWithEmail( email, token ) {
    let reqOptions = Object.assign( {}, fetchOptions( false, token ), {
        method: 'GET'
    } );
    let fields = '*,user_created.id,user_created.email';
    return fetch( process.env.PREACT_APP_API_URL + '/items/' + personConst.STUDENT + '?fields=' + fields + '&filter={"user_created.email":{"_eq":"'+email+'"}}&sort=-id', reqOptions );
}

function fetchStudents( params ) {
    let reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'GET'
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/' + personConst.STUDENT + '?' + params.join( "&" ), reqOptions );
}

function fetchCareers( params ) {
    let reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'GET'
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/' + personConst.CAREER + '?' + params.join( "&" ), reqOptions );
}

function createProfile( token, type, user ) {
    let reqOptions = Object.assign( {}, fetchOptions( false, token ), {
        method: 'POST',
        body: JSON.stringify( user )
    } );
    
    return fetch( process.env.PREACT_APP_API_URL + '/items/' + type, reqOptions );
}

function deleteProfile( type, ids, token ) {
    let reqOptions = Object.assign( {}, fetchOptions( false, token ), {
        method: 'DELETE'
    } );
    
    return fetch( process.env.PREACT_APP_API_URL + '/items/' + type + '/' + ids.join( ',' ), reqOptions );
}

function updateProfile( type, id, profile ) {
    let reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'PATCH',
        body: JSON.stringify( profile )
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/' + type + '/' + id, reqOptions );
}


function newStudent( full_user ) {
    let reqOptions = Object.assign( {}, fetchOptions(true), {
        method: 'POST',
        body: JSON.stringify( full_user )
    } );

    return fetch( process.env.PREACT_APP_API_URL + '/items/' + personConst.STUDENT+'1', reqOptions )
}

function newStudentAdmin( full_user ) {
    let reqOptions = Object.assign( {}, fetchOptions(), {
        method: 'POST',
        body: JSON.stringify( full_user )
    } );

    return fetch( process.env.PREACT_APP_API_URL + '/items/' + personConst.STUDENT, reqOptions )
}

function newStaffAdmin( profile, type ) {
    var url = '';
    if( type === personConst.MENTOR){
        profile.role = '8b841c24-76f0-4d68-8b93-146772531d23';
        url = 'mentors';
    }else if( type === personConst.CAREER){
        profile.role = '207c6261-c5d4-46b5-b614-e018a321b39e';
        url = 'career_advisors';
    }
    let reqOptions = Object.assign( {}, fetchOptions(), {
        method: 'POST',
        body: JSON.stringify( profile )
    } );

    return fetch( process.env.PREACT_APP_API_URL + '/items/'+url, reqOptions )
}


function patchUser( user_id, user ) {
    let reqOptions = Object.assign( {}, fetchOptions(), {
        method: 'PATCH',
        body: JSON.stringify( user )
    } );

    return fetch( process.env.PREACT_APP_API_URL + '/users/' + user_id, reqOptions )
}

function deleteUser( id, token ) {
    
    let reqOptions = Object.assign( {}, fetchOptions( false, token ), {
        method: 'DELETE'
    } );

    return fetch( process.env.PREACT_APP_API_URL + '/users/' + id, reqOptions )
}