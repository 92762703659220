import { h, Component } from 'preact';
import { route } from 'preact-router';
import { connect } from 'react-redux';
import { fetchConst } from '../../../_constants/fetch.constants';
import { memoActions } from '../../../_actions/memo.actions';
import { SectorHeader, SubPreview, Sub } from './FunctionalComponents';
import { industryService } from '../../../_services/industry.service';
import { retinacss } from '../../../_helpers/retinacss';
import SingleLayout from './SingleLayout';
import MenuLinks from '../../../_helpers/MenuLinks';
import style from '../style.scss';

class SectorLoader extends Component {

    constructor( props ) {
        super( props );
        this.state = {
            initialized: false,
            subsinitialized: false,
            sector: {},
            subs: [],
        };
    }

    componentDidMount() {
        this.loadSector();
    }
    
    componentWillReceiveProps() {
        this.loadSector();
    }

    loadSector = () => {
        this.setState( {
            initialized: false
        }, () => {
            industryService.get( this.props.tag )
                .then( ( response ) => response.json(), error => {
                    this.props.dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                    this.props.dispatch( failure( error ) );
                } )
                .then( json => {
                    if(json.error || (json.data && json.data.length < 1)){
                        route(MenuLinks.NotFound, true);
                    }else{
                        this.setState( {
                            initialized: true,
                            sector: json.data[0],
                            subsinitialized: this.state.sector.single_layout,
                        }, () => {
                            if( !this.state.sector.single_layout ) {
                                industryService.getSubs( json.data[0].id )
                                    .then( ( response ) => response.json(), error => {
                                        this.props.dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                                        this.props.dispatch( failure( error ) );
                                    } )
                                    .then( json2 => {
                                        this.setState( {
                                            subsinitialized: true,
                                            subs: json2.data
                                        }, () => {
                                            if(this.props.callback) this.props.callback();
                                        } );
                                    } );
                            }
                        } );
                        
                    }
                } );
        } );
        
    }

    render() {
        
        let inlinestyle = (this.state.sector.image) ? retinacss( this.state.sector.tag, this.state.sector.image) : ``;

        return (
            <div>
                {!this.state.initialized && <div class={'page_loading'}><div class={'rocket'}></div></div>}
                {this.state.initialized && <SectorHeader sector={this.state.sector} inlinestyle={inlinestyle} />}
                {this.state.sector.single_layout && <SingleLayout sector={this.state.sector} />}
                {!this.state.sector.single_layout && this.state.subs.map((item,index) => 
                    <div class={style.sub_wrap}><SubPreview item={item} tag={this.state.sector.tag} left={index % 2 == 0} /></div>
                )
                }
            </div>
        );
    }
}

export default connect()( SectorLoader );
