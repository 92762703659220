import { memoConst } from '../_constants/memo.constants';

export const memoActions = {
    success,
    error,
    clear
};

function success( message ) {
    return { type: memoConst.SUCCESS, message };
}

function error( message ) {
    return { type: memoConst.ERROR, message };
}

function clear() {
    return { type: memoConst.CLEAR };
}
