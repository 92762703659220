import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import cx from 'classnames';
import { renderModal } from '../../_actions/modal.actions';
import { listConst } from '../../_constants/lists.constants';
import { modalConst } from '../../_constants/modal.constants';
import style from './style.scss';

class AddYourOwn extends Component {

    constructor( props ) {
        super( props );
        this.state = { initialized: ( typeof this.props.observable != 'undefined' ) ? this.props.observable : true };
    }

    handleClickOpen = () => {
        var action = "";
        if ( this.props.auth && this.props.auth.loggedIn){
            if ( this.props.type === listConst.LEARNING ) {
                action = modalConst.OPEN_LEARNING;
            } else if ( this.props.type === listConst.WORKPLACE ) {
                action = modalConst.OPEN_WORKPLACE;
            } else if ( this.props.type === listConst.EVENT ) {
                action = modalConst.OPEN_EVENT;
            } else if ( this.props.type === listConst.INSPIRATION ) {
                action = modalConst.OPEN_INSPIRATION;
            }
            this.props.dispatch( renderModal( {
                which: action
            } ) );
        }else{
            this.props.dispatch( renderModal( {
                which: modalConst.OPEN_SIGNIN
            } ) );
        }
    };

    componentWillReceiveProps( nextProps, nextState ) {
        if ( nextProps.observable ) {
            this.setState( { initialized: nextProps.observable } );
        }
    }

    render() {

        var splashClass = this.props.flip ? cx( style.addyourown_splash, style.flip, 'fullwidthbg' ) : cx( style.addyourown_splash, 'fullwidthbg' );
        var padClass = this.props.flip ? cx( style.addyourownpad, style.flip ) : cx( style.addyourownpad );
        var illustrationClass = this.props.flip ? cx( style.illustration, style.flip ) : cx( style.illustration );

        var title, content, button;
        if ( this.props.type === listConst.LEARNING ) {
            title = "Add your own learning interest areas";
            content = "Remember you can add links to learning centres that you are interested in, ones that may not be listed here.";
            button = "Add a learning link";
        } else if ( this.props.type === listConst.WORKPLACE ) {
            title = "Add businesses you are interested in";
            content = "Remember you can add other businesses or workplaces you are interested in, ones that may not be listed here.";
            button = "Add a business";
        } else if ( this.props.type === listConst.EVENT ) {
            title = "Add an event to plan your next move";
            content = "You can add other events you are interested in, ones that may not be listed here and collect them in your dashboard.";
            button = "Add an event";
        } else if ( this.props.type === listConst.INSPIRATION ) {
            title = "Add an inspiration";
            content = "You can add other inspirations you are interested in, ones that may not be listed here and collect them in your dashboard.";
            button = "Add an inspiration";
        }

        return (
            <div class={'green_section'}>
                <div class={splashClass}>
                    <div class={padClass}>
                        <h3>{title}</h3>
                        <p>{content}</p>
                        <Button color="secondary" variant="contained" onClick={this.handleClickOpen}>{button}</Button>
                    </div>
                    <div class={illustrationClass}></div>
                </div>
            </div>
        );
    }
}

function mapStateToProps( state ) {

    const { auth } = state; //(see loading in auth.reducer)
    return {
        auth
    };
}

export default connect( mapStateToProps )(AddYourOwn);