import { fetchOptions } from '../_helpers/request.helpers';

export const industryService = {
    get,
    getSubs,
    getSub,
    getSubContent
};

function get(tag) {
    const reqOptions = Object.assign( {}, fetchOptions(true), {
        method: 'GET'
    } );
    if(tag){
        return fetch( process.env.PREACT_APP_API_URL + '/items/industry?single=1&filter={"tag":{"_eq":"'+tag+'"}}&fields=*.*', reqOptions );
    }else{
        return fetch( process.env.PREACT_APP_API_URL + '/items/industry?fields=id,title,tag,image.*', reqOptions );
    }
}

function getSubs(id) {
    const reqOptions = Object.assign( {}, fetchOptions(true), {
        method: 'GET'
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/industrysubs?filter={"sector":{"_eq":"'+id+'"}}&fields=*,image.*', reqOptions );
}

function getSub(tag){
    const reqOptions = Object.assign( {}, fetchOptions(true), {
        method: 'GET'
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/industrysubs?single=1&filter={"tag":{"_eq":"'+tag+'"}}&fields=*,image.*', reqOptions );
}

function getSubContent(subid){
    const reqOptions = Object.assign( {}, fetchOptions(true), {
        method: 'GET'
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/industrysubscontent?fields=*.*&filter={"sub":{"_eq":"'+subid+'"}}&', reqOptions );
}

