import { fetchConst } from '../_constants/fetch.constants';

export const authService = {
    signIn,
    refreshtoken,
    resetPassword,
    signOutLocalStorage,
    fetchUserAndRole,
    sendResetToken
};

function signIn( email, password ) {
    const reqOptions = Object.assign( {}, fetchConst.OPTIONS, {
        method: 'POST',
        body: JSON.stringify( {
            email: email,
            password: password
        } )
    } );

    return fetch( process.env.PREACT_APP_API_URL + '/auth/login', reqOptions );
}

function refreshtoken(token){
    const reqOptions = Object.assign( {}, fetchConst.OPTIONS, {
        method: 'POST',
        body: JSON.stringify( {
            refresh_token: token
        } )
    } );

    return fetch( process.env.PREACT_APP_API_URL + '/auth/refresh', reqOptions );
}

function resetPassword( url, p, t ) {
    const reqOptions = Object.assign( {}, fetchConst.OPTIONS, {
        method: 'POST',
        body: JSON.stringify( {
            "password": p,
            "token": t
        } )
    } );

    return fetch( process.env.PREACT_APP_API_URL + url, reqOptions );
}

function sendResetToken( email ) {
    const reqOptions = Object.assign( {}, fetchConst.OPTIONS, {
        method: 'POST',
        body: JSON.stringify( {
            "email": email
        } )
    } );

    return fetch( process.env.PREACT_APP_API_URL + '/auth/password/request', reqOptions );
}

function signOutLocalStorage() {
    if ( typeof window !== 'undefined' ) localStorage.removeItem( 'token' );
}


/* auth just get's token, this function returns ID and role */
function fetchUserAndRole( token ) {
    let userOptions = Object.assign( {}, fetchConst.OPTIONS, {
        method: 'GET'
    } );
    userOptions.headers = Object.assign( { 'Authorization': 'Bearer ' + token }, fetchConst.OPTIONS.headers );

    return fetch( process.env.PREACT_APP_API_URL + '/users/me?fields=id,role.id,role.name,email', userOptions );//?fields=id,roles.role.name,email
}
