import { fetchOptions, filterQuery } from '../_helpers/request.helpers';
import { listConst } from '../_constants/lists.constants';

export const panelService = {
    getPanels,
    getTitle
};


function getPanels( type, querystring, auth, panelIDs ) {
    var filterItems = [];
    if ( panelIDs && panelIDs.length > 0 )   filterItems.push('{"id": {"_in": ['+panelIDs.join( "," )+']}}');

    if ( type === listConst.EVENT ){
        filterItems.push('{"event_date": {"_gt": "'+getDateTime()+'"}}');
        querystring += '&sort=event_date';
    }
    if ( type === listConst.INSPIRATION ) querystring += '&sort=-id';

    const reqOptions = Object.assign( {}, fetchOptions( !auth ), {
        method: 'GET'
    } );

    return fetch( process.env.PREACT_APP_API_URL + '/items/' + type + '?' + querystring + filterQuery(filterItems), reqOptions );
}

function getTitle( type, panelID ) {

    if(type === 'star'){
        const reqOptions = Object.assign( {}, fetchOptions( false ), {
            method: 'GET'
        } );
      
        return fetch( process.env.PREACT_APP_API_URL + '/items/ratings/' + panelID + '?fields=rating,eventid.title,eventid.website', reqOptions );
    }else{
        const reqOptions = Object.assign( {}, fetchOptions( true ), {
            method: 'GET'
        } );

        return fetch( process.env.PREACT_APP_API_URL + '/items/' + type + '/' + panelID, reqOptions );
    }
    
}


function getDateTime() {
    var now     = new Date(); 
    var year    = now.getFullYear();
    var month   = now.getMonth()+1; 
    var day     = now.getDate()-1;//gt than yesterday
    if(month.toString().length == 1) {
         month = '0'+month;
    }
    if(day.toString().length == 1) {
         day = '0'+day;
    }   
    return year+'-'+month+'-'+day;   
}

