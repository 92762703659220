import { fetchOptions } from '../_helpers/request.helpers';

export const actionsService = {
    get,
    add,
    patch,
    remove
};

function get( area_id ) {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'GET'
    } );
    let filter = ( area_id ) ? '?filter={"area":{"_eq":"'+area_id+'"}}' : '';
    return fetch( process.env.PREACT_APP_API_URL + '/items/actions' + filter, reqOptions );
}

function add( actionPost ) {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'POST',
        body: JSON.stringify( actionPost )
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/actions', reqOptions );
}

function patch( actionPost, action_id ) {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'PATCH',
        body: JSON.stringify( actionPost )
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/actions/'+action_id, reqOptions );
}

function remove( id ) {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'DELETE'
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/actions/' + id, reqOptions );
}
