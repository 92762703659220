import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { SectorHeader, Sub } from './FunctionalComponents';
import SoundLikeYou from '../../../components/soundlikeyou';
import AddYourOwn from '../../../components/addyourown';
import ListingPreview from '../../../components/listingpreview';
import Quotes from './Quotes';
import MenuLinks from '../../../_helpers/MenuLinks';
import { listConst } from '../../../_constants/lists.constants';
import { memoActions } from '../../../_actions/memo.actions';
import { industryService } from '../../../_services/industry.service';
import { retinacss } from '../../../_helpers/retinacss';
import { fetchConst } from '../../../_constants/fetch.constants';

class SubLoader extends Component {

    

    state = {
        initialized: false,
        sub: {},
        subcontent: []
    }

    componentDidMount() {
        this.loadSub();
    }
    
    componentWillReceiveProps() {
        this.loadSub();
    }

    loadSub = () => {

        this.setState( {
            initialized: false
        }, () => {
            industryService.getSub( this.props.sub )
                .then( ( response ) => response.json(), error => {
                    this.props.dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                    this.props.dispatch( failure( error ) );
                } )
                .then( json => {
                    if(json.data.length > 0){
                    //TitleImageSet(json.data.tag);
                        industryService.getSubContent( json.data[0].id ).then( ( response ) => response.json(), error => {
                            this.props.dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                            this.props.dispatch( failure( error ) );
                        } )
                            .then( jsonsubs => {
                                jsonsubs.data.map(item => 
                                    item.list = DOMPurify.sanitize(marked.parse(item.list))
                                );
                                this.setState( {
                                    initialized: true,
                                    sub: json.data[0],
                                    subcontent: jsonsubs.data
                                } );
                            } );
                    }else{
                        this.props.dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                        this.setState( {
                            initialized: true
                        } );
                    }
                });
        });
        
    }

    render() {
        let inlinestyle = (this.state.sub.image) ? retinacss( this.state.sub.tag, this.state.sub.image) : ``;
        
        let options = {
            type: listConst.INSPIRATION,
            link: MenuLinks.Inspiration,
            title: this.state.sub.title + ' Resources',
            sub: "See what's coming up, get involved and see what's out there."
        }

        let workplace_options = {
            type: listConst.WORKPLACE,
            link: MenuLinks.Workplaces,
            title: 'Workplaces in ' + this.state.sub.title,
            sub: 'Discover workplaces and businesses that you might be interested in.'
        }

        let events_options = {
            type: listConst.EVENT,
            link: MenuLinks.Events,
            title: 'Upcoming Events',
            sub: "See what's coming up, get involved and see what is out there.",
            button: 'View all events'
        }

        return (
            <div>
                {!this.state.initialized && <div class={'page_loading'}><div class={'rocket'}></div></div>}
                {this.state.initialized && <SectorHeader sector={this.state.sub}  inlinestyle={inlinestyle}/>}
                {this.state.initialized && this.state.subcontent.map((item,index) => 
                    <Sub sub={this.state.sub} item={item}/>
                )
                }

                {this.state.initialized && this.state.sub && <Quotes sub={this.state.sub} />}

                <SoundLikeYou />

                {this.state.sub.title && <ListingPreview options={options} sector={this.state.sub.sector} loadmore={true} />}

                {this.state.sub.title && <ListingPreview options={workplace_options} sub={this.state.sub} loadmore={true}/>}

                <ListingPreview options={events_options} />

                <AddYourOwn flip={true}  type={listConst.WORKPLACE} />
            </div>
        );
    }
}

export default connect()( SubLoader );