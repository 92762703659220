import { Component } from 'preact';
import { makeStyles, MenuItem, Icon } from '@material-ui/core';
import { renderModal } from '../../_actions/modal.actions';
import { modalConst } from '../../_constants/modal.constants';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    dropdown_item: {
        display: 'block',
        listStyle: 'none',
        fontSize: '0.8em',
        textAlign: 'center',
        fontFamily: 'Gotham Rounded A, Gotham Rounded B',
        marginLeft: '-16px',
        
        '& >a': {
            textDecoration: 'none'
        },

        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        },
        '& a': {
            textDecoration: 'none',
            color: theme.palette.secondary.main,
            textTransform: 'uppercase',
            letterSpacing: '.12em',
            color: theme.palette.primary.solidGreen
        },

        '& .MuiIcon-root': {
            display: 'block',
            margin: '3px auto 4px',
            fontSize: '21px',
            color: theme.palette.secondary.main,
            width: '100%',
            height: 'auto'
        }
    },
    underline: {
        borderBottom: 'solid 1px ' + theme.palette.underline.main,
        padding: '0 0 5px 0',
        color: theme.palette.primary.solidGreen,
        margin: '8px 0px 8px -16px',
        justifyContent: 'center',
        minHeight: 'auto'
    }
    
}));

class AddMenu extends Component {

    constructor(props) {
        super(props);
    }
    
    handleClickOpen = (e, type ) => {
        if ( this.props.auth && this.props.auth.loggedIn ) {
            this.props.dispatch( renderModal( {
                which: type
            } ) );
        } else {
            this.props.dispatch( renderModal( {
                which: modalConst.OPEN_SIGNIN
            } ) );
        }
        this.props.close();
    };

    render() {
        const style = useStyles();
        return (
            <>
                <MenuItem disableGutters={true} classes={{root:style.dropdown_item + ' ' + style.underline}}>Add to your dashboard</MenuItem>
                <MenuItem disableGutters={true} classes={{root:style.dropdown_item}}><a title="" aria-label="Event" onClick={(e) => this.handleClickOpen(e, modalConst.OPEN_EVENT)}><Icon>event</Icon>Event</a></MenuItem>
                <MenuItem disableGutters={true} classes={{root:style.dropdown_item}}><a href="#" title="" aria-label="Learning Link" onClick={(e) => this.handleClickOpen(e, modalConst.OPEN_LEARNING)}><Icon>learning</Icon>Learning Link</a></MenuItem>
                <MenuItem disableGutters={true} classes={{root:style.dropdown_item}}><a href="#" title="" aria-label="Workplace" onClick={(e) => this.handleClickOpen(e, modalConst.OPEN_WORKPLACE)}><Icon>workplace</Icon>Workplace</a></MenuItem>
                <MenuItem disableGutters={true} classes={{root:style.dropdown_item}}><a href="#" title="" aria-label="Inspiration" onClick={(e) => this.handleClickOpen(e, modalConst.OPEN_INSPIRATION)}><Icon>inspiration</Icon>Inspiration</a></MenuItem>
            </>
        );
    }
}


function mapStateToProps( state ) {
    const { auth } = state; //(see loading in auth.reducer)
    return {
        auth
    };
}

export default connect( mapStateToProps )( AddMenu );
