import { h, Component, createRef } from 'preact';
import { connect } from 'react-redux';
import { renderModal } from '../../_actions/modal.actions';
import { starActions } from '../../_actions/star.actions';
import { Icon } from '@material-ui/core';


class Star extends Component {

    constructor( props ) {
        super( props );
        this.state = {
            starred: this.props.starred,
            starredId: this.props.starredId
        };
    }

    componentWillReceiveProps( nextProps, nextState ) {
        if ( nextProps.starred != this.state.starred) {
            this.setState( { starred: nextProps.starred, starredId: nextProps.starredId } );
        }
    }

    
    starClick = () => {
        if ( this.props.auth.loggedIn ) {
            this.props.dispatch( starActions.starToggle( this.props.type, this.props.listing.id, this.state.starred, this.state.starredId ) )
                .then(json => {
                    if(json.data) this.setState( { starredId: json.data.id } );
                })
        } else {
            this.props.dispatch( renderModal( {
                which: 'SIGNIN_PROMPT',
                noredirect: true
            } ) );
        }
        this.setState( {starred: !this.state.starred});
    };


    render() {
        return (<a href="#" class={'icon_link star'} title="" onClick={e => {
            e.preventDefault();
            this.starClick();
        }} aria-label="Favourite/Unfavourite"><Icon>{( this.state.starred ? 'star' : 'star_border')}</Icon></a>);
    }
}

function mapStateToProps( state ) {
    const { auth } = state; //(see loading in auth.reducer)
    return {
        auth
    };
}

export default connect( mapStateToProps )( Star );