import { fetchOptions } from '../_helpers/request.helpers';
import { fetchConst } from '../_constants/fetch.constants';
import { memoActions } from '../_actions/memo.actions';

export const starService = {
    star,
    getStars,
    unstar
};

function getStars( type, profile_id ) {
    const reqOptions = Object.assign( {}, fetchOptions(), {
        method: 'GET'
    } );
    let filter = '';
    if ( profile_id ) {
        if ( type === 'jobs'){
            filter = '&filter={"created_by":{"_eq":"' + profile_id + '"}}&';
        }else{
            filter = '&filter={"user_created":{"_eq":"' + profile_id + '"}}&';
        }
    }
    return fetch( process.env.PREACT_APP_API_URL + '/items/fav' + type + '?fields=id,item_id' + filter, reqOptions );
}

function star( type, id ) {
    return dispatch => {
        const reqOptions = Object.assign( {}, fetchOptions(), {
            method: 'POST',
            body: JSON.stringify( {
                item_id: id
            } )
        } );
        return fetch( process.env.PREACT_APP_API_URL + '/items/fav' + type, reqOptions )
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
    }
}

function unstar( type, id ) {
    return dispatch => {
        const reqOptions = Object.assign( {}, fetchOptions(), {
            method: 'DELETE'
        } );

        return fetch( process.env.PREACT_APP_API_URL + '/items/fav' + type + '/' + id, reqOptions )
            .then( ( response ) => response, error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
    }
}
