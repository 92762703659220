import { Component } from 'preact';
import cx from 'classnames';


export default class Message extends Component {

    render() {
        var classes = cx( "message", { "error": this.props.error } );
        return (
            <p class={classes}>{this.props.message}</p>
        )
    }
    
}