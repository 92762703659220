import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { Icon } from '@material-ui/core';
import MenuLinks from '../../_helpers/MenuLinks.js';
import HeaderDropDown from '../headerdropdown/index.js';
import AppBarDropDown from '../appbardropdown';
import { authConst } from '../../_constants/auth.constants';
import { notificationsConst } from '../../_constants/notifications.constants';
import { dashboard } from '../../_constants/lists.constants';



class HeaderUser extends Component {

    constructor( props ) {
        super( props );
        this.state = {
            addmenuopen: false,
            profilemenuopen: false,
            notificationsopen: false
        }
    }

    componentWillReceiveProps( nextProps ) {
        this.setState({
            notificationsopen: nextProps.notifications.open
        })
    }

    
    toggleProfileMenu = () => {
        this.setState(state => ({
            profilemenuopen: !state.profilemenuopen,
            addmenuopen: false
        }));
    }

    toggleNotifications = (e) => {
        e.preventDefault();
        if(this.props.notifications.open){
            this.props.dispatch({ type: notificationsConst.CLOSE });
        }else{
            this.props.dispatch({ type: notificationsConst.OPEN });
        }
        return false;
    }


    logout = (e) => {
        e.preventDefault();
        this.props.getOut();
        this.toggleProfileMenu();
    }

    render() {
        
        let profileDropdownItems = [
            {title: 'Dashboard', link: MenuLinks.Dashboard},
            {title: 'Account', link: MenuLinks.Account},
            {title: 'Logout', onClick: this.logout}
        ];

        let notis =  false;
        if (this.props.dash.tag){
            notis = (this.props.dash.tag === dashboard.STUDENTS && this.props.dash.id);
        }

        let adminrole = this.props.auth.role === authConst.ROLE_ADMIN;

        let hasAvatar = this.props.profile.avatar && this.props.profile.avatar.id;
        
        

        return (
            <>
                <ul class={'user_menu'}>
                    {notis && adminrole && <li><a href="#" onClick={this.toggleNotifications} title="" aria-label="Notifications" class={'hu_notification'}><Icon style={{ fontSize: 20 }}>bell</Icon></a></li>}
                    <li>
                        <HeaderDropDown addMenu={true} />
                    </li>
                    <li class={'hu_user_profile'}>
                        <AppBarDropDown items={profileDropdownItems}>
                            {hasAvatar && !adminrole && <img src={process.env.PREACT_APP_API_URL+'/assets/'+this.props.profile.avatar.id+'?fit=inside&width=28&height=28&quality=80'} class={'hu_profile_pic'} />}
                            {adminrole && <img src={'/assets/images/aoraki-logo.jpg'} class={'hu_profile_pic'} />}
                            {!hasAvatar && !adminrole && <Icon>user_profile</Icon>}
                        </AppBarDropDown>
                    </li>
                </ul>
            </>
        );
    }
}

function mapStateToProps( state ) {

    const { auth, dash, notifications, profile } = state; //(see loading in auth.reducer)
    return {
        auth,
        dash,
        notifications,
        profile
    };
}

export default connect( mapStateToProps )( HeaderUser );