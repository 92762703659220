import { fetchOptions } from '../_helpers/request.helpers';

export const ratingService = {
    get,
    add,
    patch
};

function get(eventid) {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'GET'
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/ratings?filter={"eventid":{"_eq":"' + eventid + '"}}&', reqOptions );
}

function add( rating, comment, eventid ) {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'POST',
        body: JSON.stringify( { rating: rating, comments: comment, eventid: eventid } )
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/ratings', reqOptions );
}

function patch( rating, comment, id ) {
    const reqOptions = Object.assign( {}, fetchOptions( false ), {
        method: 'PATCH',
        body: JSON.stringify( { rating: rating, comments: comment } )
    } );
    return fetch( process.env.PREACT_APP_API_URL + '/items/ratings/' + id, reqOptions );
}
