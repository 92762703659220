import { modalConst } from '../_constants/modal.constants';

const modal_initial = {
    modalType: null,
    modalProps: {}
}

export function modal( state = modal_initial, action ) {
    switch ( action.type ) {
        case modalConst.RENDER:
            return action;
        case modalConst.REMOVE:
            return modal_initial;
        default:
            return state
    }
}
