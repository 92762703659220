import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { retinacss } from '../../_helpers/retinacss';
import { memoActions } from '../../_actions/memo.actions';
import { starActions } from '../../_actions/star.actions';
import { renderModal } from '../../_actions/modal.actions';
import { niceDateTime } from '../../_helpers/dates';
import { listConst } from '../../_constants/lists.constants';
import AddYourOwn from '../../components/addyourown';
import Results from './../../components/results';
import Rating from './../../components/rating';
import Star from './../../components/star';
import style from './style.scss';
import { Grid, Button } from '@material-ui/core';
import { fetchConst } from '../../_constants/fetch.constants';
import { modalConst } from '../../_constants/modal.constants';

class SingleEvent extends Component {

    constructor( props ) {
        super( props );
        this.state = {
            item: {},
            loadingdata: true
        }
    }

    componentDidMount() {
        this.loadEvent( this.props.matches.id );
    }

    componentWillReceiveProps( nextProps ) {
        this.loadEvent( nextProps.matches.id );
    }

    loadEvent = ( id ) => {
        this.setState( {
            loadingdata: true
        }, () => {
            if ( window ) window.scrollTo( 0, 0 );
            fetch( process.env.PREACT_APP_API_URL + '/items/event/' + id + '?fields=*,image.*' )
                .then( ( response ) => response.json(), error => {
                    this.props.dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                    this.props.dispatch( failure( error ) );
                } )
                .then( json => {
                    if ( this.props.loggedIn ) {
                        this.props.dispatch( starActions.starsUponThars( listConst.EVENT, [json.data] ) )
                            .then( output => {
                                this.setState( {
                                    item: json.data,
                                    loadingdata: false
                                } );
                            } );
                    } else {
                        this.setState( {
                            item: json.data,
                            loadingdata: false
                        } );
                    }

                    
                } );
        } );
        
    }

    toggleRatingForm = () => {
      if(!this.props.loggedIn){
          this.props.dispatch( renderModal( {
              which: modalConst.OPEN_SIGNIN,
              noredirect: true
          } ) );
      }else{
          if(location){
            location.hash = "#";
            location.hash = "#rate";
          } 
          //this.setState( state => ( { ratingformopen: !state.ratingformopen } ) );
      }
  }

    render() {
        let article = this.state.item;

        var date = '';
        if ( !this.state.loadingdata && article.event_date ) {
            date = niceDateTime( article.event_date, article.event_time );
        }
        const qp = {
            'limit': 4
        }
        let inlinestyle = ( article.image && !this.state.loadingdata ) ? retinacss( 'event_splash', article.image ) : ``;
        let hascontent = ( article.section_1_image) || ( article.section_1 && article.section_1.length > 12 );
        let shortWebsite = ( article.website && article.website.length > 30 ) ? article.website.substr(0,27) + '...' : article.website;

        return (
            <div class={'content_wrap '+style.events_wrap}>
                <div class={'event_splash fullwidthbg'}>
                    <Grid container spacing={2} className={'header_content '+ style.header_content}>
                        <Grid item sm={6} xs={12}>
                            {!this.state.loadingdata && <h1>{article.title}</h1>}
                            {date && <p>{date}</p>}
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            {article.rating && <Button color="secondary" variant="contained" onClick={this.toggleRatingForm}>Rate this event</Button>}
                        </Grid>
                    </Grid>
                </div>
                <div class={style.startoggle}>
                    <div class={style.moveup}><Star listing={article} starred={article.starred} starredId={article.starredId} type={listConst.EVENT} /></div>
                </div>
                {article.rating && <Rating eventid={article.id} />}
                <div class={'dark_blue_header '+style.events_wrap_header}>
                    <style scoped dangerouslySetInnerHTML={{__html: inlinestyle}}></style>
                </div>
                <div class={style.header_sub_content}>
                    <Grid container className={style.bluecontent}>
                        <Grid item xs={2}>
                            {!this.state.loadingdata && <p>
                                <span>Event:<br /></span>
                                {date && <span>When:<br /></span>}
                                {article.event_location && <span>Where:<br /></span>}
                                {article.website && <span>Website:</span>}
                            </p>}
                        </Grid>
                        <Grid item xs={10} sm={4}>
                            {!this.state.loadingdata && 
                                <p>{article.title}<br />
                                    {date && <span>{date}<br /></span>}
                                    {article.event_location && <span>{article.event_location}<br /></span>}
                                    {article.website && <span><a style={{color:'#FFF'}} href={article.website}>{shortWebsite}</a></span>}
                                </p>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {!this.state.loadingdata && <iframe class={style.map} src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyBDVpJYSmOctxta5IX_cgXn6WE0_ssBUiY&q='+article.event_location}></iframe>}
                        </Grid>
                    </Grid>
                    {!this.state.loadingdata && <p>{article.short_blurb}</p>}
                </div>
                
                <div class={style.subitem}>
                    <Grid container spacing={5} className={style.description}>
                        {!this.state.loadingdata && <Grid item xs={12} dangerouslySetInnerHTML={{__html: article.description}}>
                        </Grid>}
                    </Grid>
                    {hascontent && <Grid container className={'pad '+style.overline}>
                        <Grid item xs={12}>
                            {article.section_1_image && <img src={process.env.PREACT_APP_API_URL+'/assets/'+article.section_1_image.id} />}
                        </Grid>
                        {article.section_1 && <Grid item xs={12} dangerouslySetInnerHTML={{__html: article.section_1}}></Grid>}
                    </Grid>}
                </div>
                {false && <div class={'padout'}>
                    <Button secondary={true} onClick={this.signInClickOpen}>Rate this event</Button>
                    <p></p>
                </div>}
                <div class={'padout '+style.overline}>
                    <h2>Other Events <sub class="centered">See what's coming up, get involved and see what is out there.</sub></h2>
                </div>
                <div class={'padout'}>
                    <Results type={listConst.EVENT} params={qp}/>
                </div>
                <AddYourOwn flip={true}  type={listConst.EVENT} />
            </div>
        );
    }
}

function mapStateToProps( state ) {
    const { loggedIn } = state.auth;
    return {
        loggedIn
    };
}

export default connect( mapStateToProps )( SingleEvent );
