import { Component } from 'preact';
import { connect } from 'react-redux';
import RatingForm from './ratingform';

class Rating extends Component {

    constructor() {
        super();
 
        this.state = {
            rating: false,
            ratingformopen: true,
            loggedIn: false
        }
    }

    componentDidMount() {
        this.setState({
            loggedIn: this.props.loggedIn
        })
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.loggedIn !== prevState.loggedIn){
            let ratingformo = nextProps.loggedIn;
            return {loggedIn : nextProps.loggedIn, ratingformopen : ratingformo};
        }
        else return null;
    }

    render() {
        return (
            <>
                {this.state.ratingformopen && <RatingForm eventid={this.props.eventid} />}
            </>
        );
    } q
}

function mapStateToProps( state ) {
    const { loggedIn } = state.auth; //(see loading in auth.reducer)
    return {
        loggedIn
    };
}

export default connect( mapStateToProps )( Rating );