export default {
    Home : '/',
    
    Events : '/events',
    HostAnEvent : '/getintouch',
    
    Workplaces : '/workplaces',
    IndustrySectors :'/workplaces/sectors',
    EmployersGetInvolved : '/workplaces/getinvolved',
    BecomeAMentor : '/workplaces/mentoring',

    StayLearning : '/staylearning',
    
    Inspiration : '/inspiration',

    GetInTouch : '/getintouch',

    Dashboard : '/dashboard',
    DashInspiration : '/dashboard/inspiration',
    DashEvents : '/dashboard/events',
    DashWorkplaces : '/dashboard/workplaces',
    DashLearning : '/dashboard/learning',
    TransitionPlan : '/transitionplan',

    JobBoard : '/jobboard',

    ListAJob : '/listajob',

    Login : '/login',

    Reset : '/reset-password',

    Launch : '/launch',

    Forgot : '/forgot',

    Invited : '/accept-invite',

    Account : '/account',
   
    NotFound : '/404',

    About : '/about',
   
    Privacy : '/privacy'
}