import MenuLinks from '../_helpers/MenuLinks';
import { handleJsonResponse, handleNetworkError, filterQuery } from '../_helpers/request.helpers';
import { profileService } from '../_services/profile.service';
import { profileConst } from '../_constants/profile.constants';
import { studentsConst } from '../_constants/students.constants';
import { personConst } from '../_constants/person.constants';
import { authConst } from '../_constants/auth.constants';
import { fetchConst } from '../_constants/fetch.constants';
import { memoActions } from './memo.actions';
import { removeModal } from './modal.actions';
import { dashboard } from '../_constants/lists.constants';
import { staffConst } from '../_constants/staff.constants';


export const profileActions = {
    fetchStudentProfile,
    patchStudentProfile,
    fetchStaffProfile,
    createStudent,
    createStudentAdmin,
    createStaffAdmin,
    remove,
    patchProfile,
    inviteEmail,
    load
};


function inviteEmail( role, id, profile ) {
    return dispatch => {
        return profileService.updateProfile( role, id, profile )
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } );
    }
}
 
function load( type, id ) {
    return dispatch => {
        var f = 'mentor';
        if(type == personConst.CAREER) f = 'school_contact';
        var params = [
            'fields=*,mentor.id,mentor.first_name,mentor.last_name,school_contact.id,school_contact.first_name,school_contact.last_name,user_created.id',
            'filter={"'+f+'":{"_eq":"'+id+'"}}',
            'sort=first_name'
        ] 
        profileService.fetchStudents( params )
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( json => {
                if ( json && json.data ) {
                    dispatch( success( json.data ) );
                } else {
                    dispatch( failure( json ) );
                }
            } );
    }

    function success( students ) {
        return { type: studentsConst.LOADED, students };
    }

    function failure( error ) {
        return { type: studentsConst.UNLOADED, error };
    }
}

function remove( type, id, redirect ) {
    //remove from appropriate table, then remove user
    return dispatch => {
        profileService.deleteProfile( type, [ id ] )
            .then( ( response ) => response.text(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( text => {
                if ( !text ) {
                    if ( type == personConst.MENTOR ) {
                        dispatch( mentor_success( id ) );
                        redirect( MenuLinks.Dashboard + '/' + dashboard.MENTORS );
                    } else if ( type == personConst.CAREER ) {
                        dispatch( career_success( id ) );
                        redirect( MenuLinks.Dashboard + '/' + dashboard.CAREERS );
                    }
                } else {
                    //dispatch( failure( text ) );
                }
            } );
        
    }

    function mentor_success( id ) {
        return { type: staffConst.MENTORS_REMOVED, id };
    }

    function career_success( id ) {
        return { type: staffConst.CAREERS_REMOVED, id };
    }
}


function patchStudentProfile( id, profile, email ) {
    return patchProfile( personConst.STUDENT, id, profile, email );
}

function patchProfile( role, id, profile, email, password ) {
    return dispatch => {
        var user = {}
        if ( email ) {
            user.email = email;
        }
        if ( role === personConst.ADMIN ) {
            if ( password ) {
                user.password = password;
            }
            return profileService.patchUser( id, user )
                .then( ( response ) => response.json(), error => {
                    dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                    dispatch( failure( error ) );
                } );
        } else {
            return profileService.updateProfile( role, id, profile )
                .then( ( response ) => response.json(), error => {
                    dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                    dispatch( failure( error ) );
                } )
                .then( json => {
                    if( role === personConst.CAREER || role === personConst.MENTOR ){
                        var which_patch = ( role === personConst.CAREER ) ? staffConst.CAREERS_PATCHED : staffConst.MENTOR_PATCHED;
                        dispatch( { type: which_patch, profile: json.data } );
                    }
                    if((profile.first_name && profile.last_name) || password){

                        if(profile.first_name && profile.last_name){
                            user.first_name = profile.first_name;
                            user.last_name = profile.last_name;
                        }
                        if ( password ) {
                            user.password = password;
                        }

                        return profileService.patchUser( json.data.user_created, user )
                            .then( ( response ) => response.json(), error => {
                                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                                dispatch( failure( error ) );
                            } );
                    } else {
                        return new Promise( ( resolve, reject ) => {
                            resolve( json );
                        } );
                    }
                } );
        }
    }
}

function fetchStaffProfile( token, loggingInSoRedirect, staffType, handleRedirect ) {
    return dispatch => {
        profileService.fetchMyStaffProfile( token, staffType )
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( json => {
                if ( json.data && json.data.length > 0 ) {
                    dispatch( success( json.data[ 0 ] ) );
                    dispatch( signinfinished() );
                    dispatch( removeModal() );
                    dispatch( memoActions.clear() );
                    if ( loggingInSoRedirect ) handleRedirect();
                } else {
                    //dispatch( createStudentProfile( token ) );
                    dispatch( signinfinished() );
                    dispatch( failure( 'Profile could not be loaded' ) );
                    dispatch( memoActions.error( 'Profile could not be loaded' ) );
                }
            },
            error => {
                dispatch( signinfinished() );
                dispatch( failure( error ) );
                dispatch( memoActions.error( error ) );
            }
            );
    };

    function success( profile ) { return { type: profileConst.SUCCESS, profile } }

    function failure( error ) { return { type: profileConst.FAILURE, error } }

    function signinfinished() { return { type: authConst.SIGNIN_FINISHED } }
}

function fetchStudentProfile( token, loggingInSoRedirect, handleRedirect ) {
    return dispatch => {
        profileService.fetchMyStudentProfile( '*.*', token )
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( json => {
                if ( json.data && json.data.length > 0 ) {
                    if ( json.data.length > 1 ) {
                        var todelete = [];
                        for ( var k = 1; k < json.data.length; k++ ) {
                            todelete.push( json.data[ k ].id );
                        }
                        if ( todelete.length > 0 ) {
                            profileService.deleteProfile( personConst.STUDENT, todelete, token );
                        }
                    }
                    dispatch( success( json.data[ 0 ] ) );
                    dispatch( signinfinished() );
                    dispatch( removeModal() );
                    dispatch( memoActions.clear() );
                    if ( loggingInSoRedirect ) handleRedirect();
                } else {
                    //dispatch( createStudentProfile( token ) );
                    dispatch( signinfinished() );
                    dispatch( failure( 'Profile could not be loaded' ) );
                    dispatch( memoActions.error( 'Profile could not be loaded' ) );
                }
            },
            error => {
                dispatch( signinfinished() );
                dispatch( failure( error ) );
                dispatch( memoActions.error( error ) );
            }
            );
    };

    function success( profile ) { return { type: profileConst.SUCCESS, profile } }

    function failure( error ) { return { type: profileConst.FAILURE, error } }

    function signinfinished() { return { type: authConst.SIGNIN_FINISHED } }
}

function createStudent( stu, extras ) {
    return dispatch => {
        return profileService.newStudent( stu )
            .then( 
                response => response, 
                error => handleNetworkError( error, dispatch )
            );
    }
}

function createStudentAdmin ( stu ){
    return dispatch => {
        return profileService.newStudentAdmin( stu )
            .then( 
                response => handleJsonResponse( response, dispatch ), 
                error => handleNetworkError( error, dispatch )
            );
    }
}

function createStaffAdmin ( profile, type ){
    return dispatch => {
        return profileService.newStaffAdmin( profile, type )
            .then( 
                response => handleJsonResponse( response, dispatch ), 
                error => handleNetworkError( error, dispatch )
            );
    }
}


