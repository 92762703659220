import { panelService } from '../_services/panel.service';
import { listConst } from '../_constants/lists.constants';

export const panelActions = {
    getPanels
};


function getPanels( type, params, auth, panelIDs ) {
    return panelService.getPanels(type, queryString(type, params), auth, panelIDs);
}

function queryString(type, params){
    var baseProps = {
        'fields': 'id,title,image.id,image.filename_download,image.type,image.width,image.height,website,sub_title,overlay_desc' +
            ( type === listConst.EVENT ? ',event_date' : '' ) +
            ( type === listConst.LEARNING ? ',short_description' : '' ) +
            ( type === listConst.JOBS ? ',description,workplace_name,workplace.*,workplace.image.id,image.filename_download,workplace.image.id,workplace.image.filename_download,workplace.image.type,workplace.image.width,workplace.image.height' : '' ) +
            ( type === listConst.INSPIRATION ? ',type' : '' ),
        'meta': 'total_count,filter_count',
        'offset': 0,
        'limit': 300
    }
    var p = Object.assign( {}, baseProps, params );

    return Object.keys( p )
        .map( key => key + '=' + p[ key ] )
        .join( '&' );
}