import { Component } from 'preact';
import { connect } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle, Link, TextField, Button, LinearProgress, makeStyles, Grid } from '@material-ui/core';
import Message from '../../message';
import linkState from 'linkstate';
import { areasActions } from '../../../_actions/areas.actions';
import { removeModal } from '../../../_actions/modal.actions';
import { memoActions } from '../../../_actions/memo.actions';

class AddArea extends Component {

    constructor( props ) {
        super( props );

        this.state = {
            editing_id: 0,
            processing: false,
            name: '',
            description: ''
        };
    }

    componentDidMount() {
        if ( this.props.modal_props.area_id > 0 ) {
            var tempAction = this.props.areas.filter( obj => obj.id === this.props.modal_props.area_id )[ 0 ];
            this.setState( {
                editing_id: this.props.modal_props.area_id,
                name: tempAction.title,
                description: tempAction.description
            } );
        }
    }

    handleSubmitViaEnter = ( e ) => {
        if ( e.key === 'Enter' ) {
            this.handleSubmit( e );
        }
    }

    handleSubmit = ( e ) => {
        e.preventDefault();
        const { name, description } = this.state;

        if ( name ) {
            this.setState( { processing: true, message: '', error: false }, () => {
                const { dispatch } = this.props;
                //profileid is required for admins/mentors/careers
                //Potential for dash.id to be not declared (page url loaded directly)
                var areaPost = {
                    title: name,
                    description: description
                };
                if ( this.props.modal_props.area_id ) {
                    dispatch( areasActions.patch( areaPost, this.props.modal_props.area_id ) );
                } else {
                    dispatch( areasActions.add( name, description, this.props.dash.id ) );
                }
            } );
        } else {
            this.setState( { message: 'Please supply a name', error: true } );
        }
    }

    closeDialogue = ( e ) => {
        this.props.dispatch( memoActions.clear() );
        setTimeout( () => {
            this.props.dispatch( removeModal() )
        }, 500 )
    }

    render( {}, { name, description, processing } ) {
        let actionText = ( this.state.editing_id ? 'Save' : 'Create' ) + ' Focus Area';
        let titleText = ( this.state.editing_id ? 'Edit' : 'Add' ) + ' Focus Area';

        return (
            <Dialog className="whiter" open={this.props.open} onCancel={this.closeDialogue} fullWidth maxWidth="md">
                <form onSubmit={this.handleSubmit} onKeyPress={this.handleSubmitViaEnter} autocomplete="off">
                    <DialogTitle>{titleText}</DialogTitle>
                    {processing && <LinearProgress indeterminate />}
                    <DialogContent>
                        {this.state.message && !this.props.memo.message &&
                            <Message message={this.state.message} error={this.state.error} />
                        }
                        {this.props.memo.message &&
                            <Message message={this.props.memo.message} error={this.props.memo.error} />
                        }
                        <Grid container spacing={2}>
                            <Grid xs={6} item>
                                <TextField value={name} onInput={linkState(this, 'name')} name="name" label={'Focus area name...'} dense={true} fullWidth />
                            </Grid>
                            <Grid xs={12} item>
                                <TextField value={description} onInput={linkState(this, 'description')} name="description" label={'Description...'} dense={true} fullWidth />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialogue}>Cancel</Button>
                        <Button variant="outlined" type="submit">{actionText}</Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

function mapStateToProps( state ) {
    const { memo, modal, areas, dash } = state;
    return {
        modal,
        memo,
        areas,
        dash 
    };
}

export default connect( mapStateToProps )( AddArea );
