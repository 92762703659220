import { customLinksConst } from '../_constants/customlinks.constants.js';

const dash_initial = {
    change: 0
}

export function customLinks( state = dash_initial, action ) {
    switch ( action.type ) {
        case customLinksConst.RELOAD:
            return { change: state.change + 1 };
        default:
            return state
    }
}
