import { areasConst } from '../_constants/areas.constants';
import { fetchConst } from '../_constants/fetch.constants';
import { areasService } from '../_services/areas.service';
import { memoActions } from './memo.actions';
import { removeModal } from './modal.actions';

export const areasActions = {
    load,
    add,
    patch,
    remove
};

function load( user_created_id ) {
    return dispatch => {
        var id = ( user_created_id ) ? user_created_id : null;
        return areasService.get( user_created_id )
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( json => {
                if ( json && json.data ) {
                    dispatch( success( json.data ) );
                } else {
                    dispatch( failure( json ) );
                }
            } );
    }

    function success( areas ) {
        return { type: areasConst.LOADED, areas };
    }

    function failure( error ) {
        return { type: areasConst.UNLOADED, error };
    }
}

function add( name, description, profile_id ) {
    return dispatch => {
        return areasService.add( name, description, profile_id )
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( json => {
                if ( json && json.data ) {
                    dispatch( success( json.data ) );
                    dispatch( removeModal() );
                    dispatch( memoActions.clear() );
                } else {
                    //dispatch( failure( json ) );
                }
            } );
    }

    function success( area ) {
        return { type: areasConst.ADD, area };
    }

    //function failure( error ) {
    //    return { type: schoolsConst.ADD_FAILED, error };
    //}
}


function patch( areaPost, area_id ) {
    return dispatch => {
        return areasService.patch( areaPost, area_id )
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( json => {
                if ( json && json.data ) {
                    dispatch( success( json.data ) );
                    dispatch( removeModal() );
                    dispatch( memoActions.clear() );
                } else {
                    //dispatch( failure( json ) );
                }
            } );
    }

    function success( area ) {
        return { type: areasConst.PATCH, area };
    }

    //function failure( error ) {
    //    return { type: schoolsConst.ADD_FAILED, error };
    //}
}


function remove( id ) {
    return dispatch => {
        return areasService.remove( id )
            .then( ( response ) => response.text(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( text => {
                if ( !text ) {
                    dispatch( success( id ) );
                    //Route( MenuLinks.Dashboard + '/' + dashboard.SCHOOLS );
                } else {
                    dispatch( failure( text ) );
                }
            } );
    }

    function success( id ) {
        return { type: areasConst.REMOVE, id };
    }

    function failure( error ) {
        //return { type: schoolsConst.REMOVE_FAILED, error };
    }
}