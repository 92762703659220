import { red } from '@material-ui/core/colors';
//import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
    breakpoints: { 
        values: {
            xs: 0,
            sm: 600,
            md: 840,
            lg: 960,
            xl: 1200
        }
    },
    palette: {
        primary: {
            main: '#18254a', //darkblue
            light: '#23325f',
            dark: '#0C152E',
            darkAlt: '#1d3956',
            solidGreen: '#48B394',
            solidGreenDark: '#356d5c',
            //greenGradient: linearGradient('#48B394', '#479172'), 
            title: '#4a4a4a',
            text: '#909090',
            lightGrey: '#f2f2f2',
            inputGrey: '#f5f5f5',
            hoverGrey: '#eee',
            focusGrey: '#ddd',
            activityGrey: '#eaedf2'
        },
        secondary: {
            main:  '#f9ea06', // yellow,
            dark: 'rgba(225, 210, 5, 1)'
        },
        underline: {
            main:  '#323d60',
            light: '#CCC'
        },
        error: {
            main: red.A400
        },
        background: {
            default: '#fff'
        }
    },
    typography: {
        fontFamily: [
            'museo-sans',
            'sans-serif'
        ].join(','),
        fontSize: 16
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': ['museo-sans'],
            },
        },
        MuiButton: {
            root: {
                fontFamily: [
                    'Gotham Rounded A',
                    'Gotham Rounded B'
                ].join(','),
                letterSpacing: '.08929em',
                padding: '11px 1.7em',
                borderRadius: '2px',
                fontSize: '.875rem'
            },
            outlined: {
                padding: '11px 1.7em',
            },
            text: {
                padding: '11px 1.7em',
            }
        },
        MuiDialogTitle: {
            root: {
                color: '#fff',
                backgroundColor: '#18254a',
                fontFamily: [
                    'Gotham Rounded A',
                    'Gotham Rounded B'
                ].join(',')
            }
        },
        MuiDialogActions: {
            root: {
                backgroundColor: '#48B394',
                padding: '8px 24px'
            }
        },
        MuiDialogContent: {
            root: {
                padding: '24px 24px'
            }
        },
        MuiLink: {
            underlineHover: {
                '&:hover': {
                    textDecoration:'none'
                }
            }
        },
        MuiInput: {
            root: {
                padding: '8px 8px 0px 8px'
            }
        },
        MuiTextField: {
            root: {
                display: 'block',
                borderRadius: '4px 4px 0 0',
                marginTop: '1.1em',
                marginBottom: '1.1em',
                backgroundColor: '#f5f5f5',
                '&:hover': {
                    backgroundColor: '#eee'
                }
            }
        },
        MuiFormControl: {
            root: {
                minWidth: 200
            }
        },
        MuiFormLabel: {
            root: {
                paddingLeft: 8,
                paddingRight: 8,
                paddingTop: 8
            }
        },
        MuiTab: {
            root: {
                fontFamily: [
                    'Gotham Rounded A',
                    'Gotham Rounded B'
                ].join(','),
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)'
                }
            }
        },
        MuiSnackbarContent: {
            root: {
                fontSize: '0.95rem',
                backgroundColor: '#1d3956'
            }
        }
    }
});

export default theme;
