export const authConst = {
    AUTH_REQUEST: 'USERS_AUTH_REQUEST',
    AUTH_SUCCESS: 'USERS_AUTH_SUCCESS',
    AUTH_FAILURE: 'USERS_AUTH_FAILURE',

    USER_ROLE_SUCCESS: 'USER_ROLE_SUCCESS',

    SIGNIN_FINISHED: 'SIGNIN_FINISHED',
    SIGNOUT: 'USERS_SIGNOUT',

    ROLE_STUDENT: 'Students',
    ROLE_CAREER_ADVISORS: 'Career Advisors',
    ROLE_ADMIN: 'Admin',
    ROLE_MENTOR: 'Mentors'
};
