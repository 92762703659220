import { fetchConst } from '../_constants/fetch.constants';
import { memoActions } from '../_actions/memo.actions';

export function handleJsonResponse( response, dispatch ){
    if (response.ok) {
        return response.json();
    } else {
        if ( response.status && response.statusText ){
            dispatch( memoActions.error( response.status + ': ' + response.statusText ) );
        }else{
            response.json().then( (json) => {
                if( json.error && json.error.message ){
                    dispatch( memoActions.error( json.error.message ) );
                }else if (json.errors && json.errors.length > 0 && json.errors[0].message){
                    dispatch( memoActions.error( json.errors[0].message ) );
                }else{
                    dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                }
            });
        }
    }
}

export function handleStatusResponse( response, dispatch ){
    if (response.status == 204 || response.status == 200) {
        return response;
    } else {
        if ( response.status && response.statusText ){
            dispatch( memoActions.error( response.status + ': ' + response.statusText ) );
        }else{
            response.json().then( (json) => {
                if( json.errors && json.errors.length > 0 && json.errors[0].message ){
                    dispatch( memoActions.error( json.errors[0].message ) );
                }else{
                    dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                }
            });
        }
    }
}

export function filterQuery( filters, andor ){
    if( !andor ) andor = '_and';
    if(filters.length > 0){
        return '&filter={"'+andor+'":[' + filters.join( "," ) + ']}';
    }
    return '';
}

export function handleTextResponse( response, dispatch ){
    if (response.ok) {
        return response.text();
    } else {
        dispatch( memoActions.error( response.status + ': ' + response.statusText ) );
    }
}

export function handleNetworkError(error, dispatch){
    if( error.type && error.message ){
        dispatch( memoActions.error( error.type + ': ' + error.message ) );
    }else{
        dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
    }
    //fail(error.type + ': ' + error.message);
}

export function handleCatchError( dispatch ){
    dispatch( memoActions.error( fetchConst.CATCH_ERROR ) );
}

export function fetchOptions( excludeToken, token ) {
    var options = Object.assign( {}, fetchConst.OPTIONS );
    if ( !excludeToken ) options.headers = Object.assign( authHeader( token ), fetchConst.OPTIONS.headers );
    return options;
}

function authHeader( supplied_token ) {
    let token;
    if ( supplied_token ) {
        token = supplied_token;
    } else {
        token = ( typeof window !== 'undefined' ) ? localStorage.getItem( 'token' ) : undefined;
    }
    if ( token ) {
        return { 'Authorization': 'Bearer ' + token };
    } else {
        return {};
    }
}
