import { actionsConst } from '../_constants/actions.constants';

export function actions( state = [], action ) {
    switch ( action.type ) {
        case actionsConst.LOADED:
            return action.actions;
        case actionsConst.ADD:
            let newArray = state.slice();
            newArray.push( action.action )
            return newArray;
        case actionsConst.PATCH:
            let n = state.slice();
            let index = n.findIndex(({ id }) => id === action.action.id);
            n[index] = action.action;
            return n;/* test if this works */
        case actionsConst.REMOVE:
            let tempArray = state.filter( obj => {
                return obj.id != action.id;
            } );
            return tempArray
        case actionsConst.UNLOADED:
            return [];
        default:
            return state;
    }
}
