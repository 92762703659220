import { starService } from '../_services/star.service';
import { fetchConst } from '../_constants/fetch.constants';
import { memoActions } from '../_actions/memo.actions';

export const starActions = {
    starToggle,
    starsUponThars
};

function starToggle( type, id, starred, starredid ) {
    //return (dispatch, getState) => {
    return dispatch => {
        if ( starred ) {
            return dispatch( starService.unstar( type, starredid ) );
        } else {
            return dispatch( starService.star( type, id ) );
        }
    }
}


function starsUponThars( type, json ) {
    return dispatch => {
        return starService.getStars( type )
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( starIds => {
                if ( starIds.data && starIds.data.length > 0 ) {
                    for ( var i = 0; i < json.length; i++ ) {
                        json[ i ].starred = ( 
                            //starIds.data.find( obj => { return obj.item_id == json[ i ].id } ) != undefined 
                            starIds.data.filter( obj => {
                                return obj.item_id == json[ i ].id
                            })[0] != undefined 
                        );
                        //if ( json[ i ].starred ) json[ i ].starredId = starIds.data.find( obj => { return obj.item_id == json[ i ].id } ).id;
                        if ( json[ i ].starred ) json[ i ].starredId = starIds.data.filter( obj => { return obj.item_id == json[ i ].id } )[0].id;
                    }
                }
                return json;
            } );
    }
}
