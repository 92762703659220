import { authConst } from '../_constants/auth.constants';
import { profileConst } from '../_constants/profile.constants';
import { fetchConst } from '../_constants/fetch.constants';
import { dashConst } from '../_constants/dash.constants';
import { authService } from '../_services/auth.service';
import { memoActions } from './memo.actions';
import { profileActions } from './profile.actions';
import { removeModal } from './modal.actions';
import { personConst } from '../_constants/person.constants';

export const authActions = {
    signIn,
    signOut,
    userAndRole,
    refreshtoken,
    sendResetToken,
    resetPassword
};

function signIn( username, password, handleRedirect ) {
    return dispatch => {
        dispatch( request( { username } ) ); /* sets state.loggingIn = true */
        authService.signIn( username, password )
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then( ( json ) => {
                if ( json.data && json.data.access_token ) {
                    if( json.data && json.data.access_token ){
                        if ( typeof window !== 'undefined' ) {
                            localStorage.setItem( "token", json.data.access_token );
                            localStorage.setItem( "refresh_token", json.data.refresh_token );
                        }
                        dispatch( memoActions.success( 'Welcome, loading your next move...' ) );
                        dispatch( success( json ) );
                        //determine role.
                        dispatch( authActions.userAndRole( json.data.access_token, true, handleRedirect ) );
                    }else{
                        dispatch( memoActions.error( 'Sign in failed, likely incorrect password or email.' ) );
                        dispatch( failure( 'Sign in failed, likely incorrect password or email.' ) );
                    }  
                } else if ( json.errors && json.errors[0].message ) {
                    dispatch( failure( '' ) );
                    dispatch( memoActions.error( 'Sign in failed, likely incorrect password or email' ) );
                    dispatch( failure( json.errors[0].message ) );
                } else {
                    dispatch( memoActions.error( 'Sign in failed, reason unknown' ) );
                    dispatch( failure( 'Unknown error' ) );
                }
            },
            ( error ) => {
                dispatch( memoActions.error( error ) );
                dispatch( failure( error ) );
            }
            );
    };

    function request( user ) { return { type: authConst.AUTH_REQUEST, user } }

    function success( user ) { return { type: authConst.AUTH_SUCCESS, user } }

    function failure( error ) { return { type: authConst.AUTH_FAILURE, error } }
}

function signOut() {
    return dispatch => {
        authService.signOutLocalStorage();
        dispatch( { type: profileConst.CLOSE } );
        dispatch( { type: dashConst.RESET } );
        dispatch( { type: authConst.SIGNOUT } );
        dispatch( removeModal() );
        dispatch( memoActions.clear() );
        //TODO reset all reducers...
    };
}


function userAndRole( token, signingin, handleRedirect ) {
    return dispatch => {
        authService.fetchUserAndRole( token )
            .then( ( response ) => response.json(), error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
            .then(
                json => {
                    if ( json.data ) {
                        dispatch( success( json.data ) );
                        if ( json.data.role.name === authConst.ROLE_STUDENT ) {
                            // Load student profile, create if it doesn't exist
                            dispatch( profileActions.fetchStudentProfile( token, signingin, handleRedirect ) );
                        } else if ( json.data.role.name === authConst.ROLE_CAREER_ADVISORS ) {
                            dispatch( profileActions.fetchStaffProfile( token, signingin, personConst.CAREER, handleRedirect ) );
                        } else if ( json.data.role.name === authConst.ROLE_MENTOR ) {
                            dispatch( profileActions.fetchStaffProfile( token, signingin, personConst.MENTOR, handleRedirect ) );
                        } else {
                            if ( signingin ) dispatch( signinfinished() );
                            dispatch( removeModal() );
                            dispatch( memoActions.clear() );
                            if ( signingin ) handleRedirect();
                        }
                    } else {
                        
                        if ( signingin ) dispatch( signinfinished() );
                        //dispatch( failure( ( json.error ) ? json.error : 'Unknown error' ) );
                        dispatch( authActions.signOut() );
                    }
                },
                error => {
                    if ( signingin ) {
                        dispatch( signinfinished() );
                        dispatch( memoActions.error( error ) );
                    }
                    dispatch( authActions.signOut() );
                }
            )
            .catch( err => {
                dispatch( memoActions.error( err ) );
            } );
    };


    function success( profile ) { return { type: authConst.USER_ROLE_SUCCESS, profile } }

    function failure( error ) { return { type: authConst.SIGNOUT, error } }

    function signinfinished() { return { type: authConst.SIGNIN_FINISHED } }
}

function sendResetToken( email ) {
    return dispatch => {
        return authService.sendResetToken( email )
            .then( ( response ) => response, error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )

    }
}


function resetPassword( url, password, token ) {
    return dispatch => {
        var t = authService.resetPassword( url, password, token )
            .then( ( response ) => response, error => {
                dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                dispatch( failure( error ) );
            } )
        return t;
    }
}

function refreshtoken() {
    return dispatch => {

        if ( typeof window !== 'undefined' ) {
            var token = localStorage.getItem( 'refresh_token' );
            if ( token ) {
                authService.refreshtoken( token )
                    .then( ( response ) => response.json(), error => {
                        dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                        dispatch( failure( error ) );
                    } )
                    .then(
                        json => {
                            if ( json && json.data && json.data.access_token ) {
                                if ( typeof window !== 'undefined' ) {
                                    localStorage.setItem( "token", json.data.access_token );
                                    localStorage.setItem( "refresh_token", json.data.refresh_token );
                                }
                            } else if ( json && json.error ) {
                                dispatch( authActions.signOut() );
                                //dispatch( failure( json.error ) );
                            } else {
                                dispatch( authActions.signOut() );
                                //dispatch( failure( json ) );
                            }
                        },
                        error => {
                            dispatch( authActions.signOut() );
                            //dispatch( failure( error ) );
                        }
                    );
            }
        }
    };

    function failure( error ) { return { type: authConst.AUTH_FAILURE, error } }
}
