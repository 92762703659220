import { memoConst } from '../_constants/memo.constants';

export function memo( state = {}, action ) {
    switch ( action.type ) {
        case memoConst.SUCCESS:
            return {
                error: false,
                message: action.message
            };
        case memoConst.ERROR:
            return {
                error: true,
                message: action.message
            };
        case memoConst.CLEAR:
            return {};
        default:
            return state
    }
}
