import { schoolsConst } from '../_constants/schools.constants';

export function schools( state = [], action ) {
    switch ( action.type ) {
        case schoolsConst.LOADED:
            return action.schools;
        case schoolsConst.ADD_SCHOOL:
            let newArray = state.slice();
            newArray.push( action.school )
            return newArray
        case schoolsConst.REMOVE_SCHOOL:
            let tempArray = state.filter(obj => {
                return obj.id != action.id;
            });
            return tempArray
        case schoolsConst.UNLOADED:
            return [];
        default:
            return state;
    }
}
