import { eventsConst } from '../_constants/events.constants';

export function events( state = [], action ) {
    switch ( action.type ) {
        case eventsConst.LOADED:
            return action.schools;
        case eventsConst.UNLOADED:
            return [];
        case eventsConst.PATCH:
              let n = state.slice();
              let index = n.findIndex(({ id }) => id === action.event.id);
              n[index] = action.event;
              return n;
        default:
            return state;
    }
}
