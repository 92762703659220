import { Component } from 'preact';
import { connect } from 'react-redux';
import { route } from 'preact-router';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField, Button, LinearProgress, FormControl, FilledInput, InputLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuLinks from '../../../_helpers/MenuLinks';
import DatePicker from '../../datepicker';
import { memoActions } from '../../../_actions/memo.actions';
import { niceDate, jsonDate } from '../../../_helpers/dates';
import { customLinksService } from '../../../_services/customlinks.service';
import { customLinksConst } from '../../../_constants/customlinks.constants.js';
import { modalConst } from '../../../_constants/modal.constants';
import { fetchConst } from '../../../_constants/fetch.constants';
import { listConst } from '../../../_constants/lists.constants';
import { removeModal } from '../../../_actions/modal.actions';

class AddLink extends Component {

    constructor( props ) {
        super( props );

        this.state = {
            editing_id: 0,
            datePickerOpened: false,
            processing: false,
            title: '',
            description: '',
            type: '',
            icon: '',
            event_date: '',
            redirect: ''
        }
    }

    componentDidMount() {
        // autofocus the input on mount
        var key = '';
        var redirect = '';

        if ( this.props.modal.modal_props.which === modalConst.OPEN_LEARNING ) {
            key = listConst.LEARNING;
            redirect = MenuLinks.DashLearning;
        } else if ( this.props.modal.modal_props.which === modalConst.OPEN_WORKPLACE ) {
            key = listConst.WORKPLACE;
            redirect = MenuLinks.DashWorkplaces;
        } else if ( this.props.modal.modal_props.which === modalConst.OPEN_EVENT ) {
            key = listConst.EVENT;
            redirect = MenuLinks.DashEvents;
        } else if ( this.props.modal.modal_props.which === modalConst.OPEN_INSPIRATION ) {
            key = listConst.INSPIRATION;
            redirect = MenuLinks.DashInspiration;
        }

        this.setState( {
            type: key,
            redirect: redirect/*,
            area: this.props.modal.modal_props.focus_area*/
        } );
        if ( this.props.modal.modal_props.custom && this.props.modal.modal_props.custom.id ) {
            this.setState( {
                editing_id: this.props.modal_props.custom.id,
                title: this.props.modal_props.custom.title,
                description: this.props.modal_props.custom.description,
                website: this.props.modal_props.custom.website,
                event_date: this.props.modal_props.custom.event_date,
                redirect: redirect
            } );
        } 
    }

    openDatePicker = () => {
        this.setState({ datePickerOpened: true });
    }
    
    closeDatePicker = () => {
        this.setState({ datePickerOpened: false });
    }

    dateReciever = (date) => {
        //2019-02-14
        if(date) this.setState({ event_date: date });
    } 
      
    saveLink = ( e ) => {
        e.preventDefault();
        this.setState( {
            processing: true
        } )
        var customLinkPost = {
            title: this.state.title,
            website: this.state.website,
            description: this.state.description,
            type: this.state.type
        };
        if ( this.state.type === listConst.INSPIRATION && this.state.icon ) {
            customLinkPost.icon = this.state.icon.toLowerCase();
        }
        if ( this.state.type === listConst.EVENT && this.state.event_date ) {
            customLinkPost.event_date = jsonDate( this.state.event_date );
        }
        if ( this.state.editing_id > 0 ) {
            customLinksService.patch( customLinkPost, this.state.editing_id )
                .then( ( response ) => response.json(), error => {
                    this.props.dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                    this.props.dispatch( failure( error ) );
                } )
                .then( json => {
                    if ( json && json.data ) {
                        this.resetForm();
                    }
                    this.props.dispatch( { type: customLinksConst.RELOAD } );
                } );
        } else {
            customLinksService.add( customLinkPost )
                .then( ( response ) => response.json(), error => {
                    this.props.dispatch( memoActions.error( fetchConst.NETWORK_ERROR ) );
                    this.props.dispatch( failure( error ) );
                } )
                .then( json => {
                    if ( json && json.data ) {
                        this.resetForm();
                        this.props.dispatch( { type: customLinksConst.RELOAD } );
                        
                        let r = ( this.state.redirect ) ? this.state.redirect : MenuLinks.Dashboard;
                        route(r,true)

                    }
                } );
        }
        return false;
    }

    closeDialogue = ( e ) => {
        this.props.dispatch( memoActions.clear() );
        setTimeout( () => {
            this.props.dispatch( removeModal() )
        }, 500 )
    }

    resetForm = () => {
        this.setState({
            processing: false,
            title: '',
            website: '',
            event_date: '',
            description: ''
        });
        this.closeDialogue();
    }

    render() {

        let actionText = (this.state.editing_id) ? 'Edit' : 'Add';
        let inspiration_icons = [ { title: 'Video', name: 'video' }, { title: 'Document', name: 'article' }, { title: 'Podcast', name: 'audio' } ];

        //todo investigate if content and button have been left out
        let title, content, button;
        if ( this.props.modal.modal_props.which === modalConst.OPEN_LEARNING ) {
            title = actionText+" Learning place";
            content = "Remember you can add links to learning centres that you are interested in, ones that may not be listed here.";
            button = actionText+" learning link";
        } else if ( this.props.modal.modal_props.which === modalConst.OPEN_WORKPLACE ) {
            title = actionText+" Workplace";
            content = "Remember you can add other businesses or workplaces you are interested in, ones that may not be listed here.";
            button = actionText+" workplace";
        } else if ( this.props.modal.modal_props.which === modalConst.OPEN_EVENT ) {
            title = actionText+" Event";
            content = "You can add other events you are interested in, ones that may not be listed here and collect them in your dashboard.";
            button = actionText+" event";
        } else if ( this.props.modal.modal_props.which === modalConst.OPEN_INSPIRATION ) {
            title = actionText+" Inspiration";
            content = "You can add other inspiration you are interested in, ones that may not be listed here and collect them in your dashboard.";
            button = actionText+" inspiration";
        }
        let event_date = (this.state.event_date) ? niceDate(this.state.event_date) : '';

        const {
            datePickerOpened
        } = this.state;
          
        return (
            <Dialog open={this.props.open} onCancel={this.closeDialogue} fullWidth maxWidth="md">
                <DialogTitle>{title}</DialogTitle>
                {this.state.processing && <LinearProgress indeterminate />}
                <DialogContent>
                    <TextField value={this.state.title} onInput={e => this.setState({ title: e.target.value })} name="Title" label={'Title...'} dense={true} fullWidth />
                    <TextField value={this.state.website} onInput={e => this.setState({ website: e.target.value })} name="URL" label="URL..." dense={true} fullWidth />
                    {this.props.modal.modal_props.which === modalConst.OPEN_EVENT && 
                        <div>
                            <TextField value={event_date} onKeyUp={this.openDatePicker} onFocus={this.openDatePicker} onClick={this.openDatePicker} name="EventDate" label="Event date..." dense={true} fullWidth />
                            <DatePicker closeFunction={this.closeDatePicker} opened={datePickerOpened} dateReciever={this.dateReciever} />
                        </div>
                    }
                    
                    <TextField value={this.state.description} onInput={e => this.setState({ description: e.target.value })} name="Description" label="Description..." multiline={true} dense={true} fullWidth />
                    {this.props.modal.modal_props.which === modalConst.OPEN_INSPIRATION && 
                        <FormControl variant="filled" fullWidth={true} margin={'dense'} className="selectNative">
                            <InputLabel htmlFor="rtype">
                                Resource Type
                            </InputLabel>
                            <Select 
                                native fullWidth 
                                value={this.state.icon}
                                onChange={(e)=>{
                                    this.setState({ icon: e.target.value });
                                }}
                                input={<FilledInput name="rtype" id="rtype" />}
                            >
                                <option value="0">None</option>
                                {inspiration_icons.map(item => 
                                    <option value={item.name}>{item.title}</option>
                                )}
                            </Select>
                        </FormControl>

                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.resetForm}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={(e) => this.saveLink(e)}>{actionText} {this.state.type}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps( state ) {
    const { modal, memo } = state;
    return {
        modal,
        memo
    };
}

export default connect( mapStateToProps )(  AddLink );
